import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  Breadcrumbs,
  DashboardCompanyHeader,
  DashboardSummaryGoal,
  DashboardSummarySafe,
  SafeNoteList
} from '@/features/dashboard'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import {
  MultiReminderModal,
  PendingSafesModal
} from '@/features/dashboard/modals'
import { useModalContext } from '@/features/modals'
import { ICompany } from '@/features/profile/types'
import { containsMissedFields, getPermission } from '@/features/profile/utils'
import { SelectSafeRecipientModal } from '@/features/safe-note/modals'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { FullScreenLoading, Loader } from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './DashboardEntrepreneurView.module.scss'

const mapStateToProps = ({
  safeNote,
  user,
  profile,
  termSheet
}: StoreType) => ({
  fetchSafeNotes: safeNote.fetchAll,
  safeNoteList: safeNote.safeNoteList,
  loading:
    safeNote.functionLoading.fetchAll ||
    safeNote.functionLoading.deleteSafeNote ||
    safeNote.functionLoading.markAsPaid ||
    termSheet.functionLoading.fetchAll,
  deleteSafeNote: safeNote.delete,
  emailVerified: user.me?.emailVerified,
  updateCompanyGoal: profile.updateCompanyGoal,
  updateGoalInStore: user.updateGoal,
  userId: user.me?.id,
  fetchTermSheets: termSheet.fetchAll,
  termSheets: termSheet.termSheetsList,
  downloadPdfLoading: safeNote.functionLoading.downloadPdf
})

type DashboardViewProps = ReturnType<typeof mapStateToProps> & {
  currentCompany: ICompany
}

const DashboardEntrepreneurView: FC<DashboardViewProps> = ({
  userId,
  currentCompany,
  emailVerified,
  fetchSafeNotes,
  safeNoteList,
  loading,
  termSheets,
  fetchTermSheets,
  updateGoalInStore,
  updateCompanyGoal,
  downloadPdfLoading,
  deleteSafeNote
}: DashboardViewProps) => {
  const [searchParams] = useSearchParams()
  const companyId = currentCompany.id

  const { setSelectedProfile } = useDashboardNavigationContext()
  const navigate = useNavigator()

  const selectedRole = currentCompany.type
  const goal = currentCompany.goal || 0

  const { visible, closeModal, openModal } = useModal<
    'pendingSafes' | 'reminders' | 'selectRecipient'
  >()
  const { fillCompanyInfoModal, verifyEmailModal, dontHavePermissionModal } =
    useModalContext()

  const permission = useMemo(
    () => getPermission(userId, currentCompany),
    [userId, currentCompany]
  )

  const goToProfileDashboard = () => {
    setSelectedProfile(ProfileTypes.ENTREPRENEUR)
    navigate.toDashboard()
  }

  const { totalAmount, totalCount, unpaidAmount, unpaidCount, pendingSafes } =
    useMemo(
      () =>
        safeNoteList?.reduce(
          (res, item) => {
            const newResult = { ...res }

            newResult.totalAmount = res.totalAmount + item.safeAmount

            if (!item.paid) {
              // @ts-ignore
              newResult.pendingSafes.push(item)
              newResult.unpaidCount = res.unpaidCount + 1
              newResult.unpaidAmount = res.unpaidAmount + item.safeAmount
            }

            return newResult
          },
          {
            totalCount: safeNoteList?.length || 0,
            unpaidCount: 0,
            unpaidAmount: 0,
            totalAmount: 0,
            pendingSafes: []
          }
        ),
      [safeNoteList]
    )

  useEffect(() => {
    fetchSafeNotes({ data: { entrepreneurCompanyId: companyId } })
    fetchTermSheets({ data: { entrepreneurCompanyId: companyId } })
  }, [companyId])

  const handleDelete = useCallback((id: string) => {
    deleteSafeNote({
      data: { id }
    })
  }, [])

  const handleCreateSafeNote = useCallback(() => {
    if (!emailVerified) {
      verifyEmailModal.openModal()
      return
    }

    if (permission !== TeamMemberPermission.CREATE) {
      dontHavePermissionModal.openModal()
      return
    }

    if (containsMissedFields(currentCompany)) {
      fillCompanyInfoModal.openModal()
      return
    }

    navigate.toCreateSafeNote(currentCompany.id)
    // openModal('selectRecipient')
  }, [emailVerified, permission, currentCompany])

  const handleUpdateGoal = (_goal: number) => {
    if (!companyId) return

    updateCompanyGoal({
      data: { id: companyId, goal: _goal }
    })
    updateGoalInStore(companyId, _goal)
  }

  useEffect(() => {
    if (searchParams.get('create-safe-note') === 'true') {
      const timer = setTimeout(() => {
        handleCreateSafeNote()
        searchParams.delete('create-safe-note')
        window.history.replaceState(null, '', window.location.pathname)
      }, 1200)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [searchParams])

  return (
    <div className="tw-w-full">
      <FullScreenLoading loading={downloadPdfLoading} />

      {visible === 'pendingSafes' && (
        <PendingSafesModal
          type={currentCompany.type}
          closeModal={closeModal}
          predefinedItems={pendingSafes}
        />
      )}

      {visible === 'selectRecipient' && (
        <SelectSafeRecipientModal
          companyId={currentCompany.id}
          closeModal={closeModal}
        />
      )}

      {visible === 'reminders' && (
        <MultiReminderModal closeModal={closeModal} safeNotes={pendingSafes} />
      )}

      <Breadcrumbs
        type={currentCompany.type}
        company={currentCompany.name}
        onTypeClick={goToProfileDashboard}
      />

      <DashboardCompanyHeader
        company={currentCompany}
        permission={permission}
      />

      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="100%" />}
      >
        <section
          className={clsx(styles.summarySection, !totalCount && styles.noSafes)}
        >
          <DashboardSummarySafe
            isEmpty={!totalCount}
            type="completed"
            permission={permission}
            role={selectedRole}
            amount={totalAmount - unpaidAmount}
            count={totalCount - unpaidCount}
            disabled={false}
            onClick={handleCreateSafeNote}
          />

          <DashboardSummarySafe
            isEmpty={!totalCount}
            type="incomplete"
            permission={permission}
            role={selectedRole}
            amount={unpaidAmount}
            count={unpaidCount}
            disabled={false}
            onClick={() => openModal('reminders')}
          />

          <DashboardSummaryGoal
            savedGoal={goal}
            permission={permission}
            companyId={companyId}
            role={selectedRole}
            pendingCount={unpaidCount}
            updateGoal={handleUpdateGoal}
            pendingAmount={unpaidAmount}
            safesAmount={totalAmount}
            openModal={() => openModal('pendingSafes')}
          />

          {/* TODO: Not ready yet */}
          {/* <DashboardUpdates role={selectedRole} /> */}
        </section>

        <section className={styles.tableSection}>
          <SafeNoteList
            termSheets={termSheets}
            emailVerified={emailVerified}
            permission={permission}
            currentCompany={currentCompany}
            data={safeNoteList}
            onDelete={handleDelete}
            role={selectedRole}
          />
        </section>
      </ConditionalRender>
    </div>
  )
}

export default withStore(mapStateToProps)(DashboardEntrepreneurView)
