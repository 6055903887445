import { FC } from 'react'

import { StaticIcons } from '@/assets/icons'
import { Avatar } from '@/common/components'
import { toCurrencyFormat } from '@/common/utils'
import goalFilledIcon from '@/features/dashboard/assets/images/summary/goal-filled.png'
import { Notifications } from '@/features/notifications'
import { ISummaryItem } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './InvestedCompanySummary.module.scss'

interface InvestedCompanySummaryProps {
  summary: ISummaryItem
  companyId: string
  innerCompany: ICompany | undefined
}

const InvestedCompanySummary: FC<InvestedCompanySummaryProps> = (props) => {
  const { companyId, summary, innerCompany } = props

  const navigator = useNavigator()
  const paidCount = summary.totalSafeCount - summary.unpaidCount

  if (!innerCompany) return null

  return (
    <div className={styles.investedCompanySummary}>
      <Row justify="between" gap={20} className={styles.summaryBlock}>
        <Row items="center" gap={14}>
          <Avatar
            size={56}
            name={innerCompany.name}
            image={innerCompany.image}
            defaultImage={StaticIcons.CompanyPlaceholder}
          />
          <Col gap={2}>
            <Heading
              weight={FontWeight.BOLD}
              color={Color.neutral500}
              type={HeadingTypes.H2}
              className="!tw-leading-snug"
            >
              {innerCompany.name}
            </Heading>

            <Text
              type={TextTypes.BODY_SMALL}
              weight={FontWeight.SEMIBOLD}
              color={Color.neutral300}
            >
              Owner:{' '}
              <span className={styles.owner}>
                {innerCompany.owner.fullName}
              </span>
            </Text>
          </Col>
        </Row>
        <Notifications
          key={companyId}
          companyId={companyId}
          minimalistic
          color={Color.neutral400}
        />
      </Row>

      <Row justify="between" gap={20} className={styles.summaryBlock}>
        <Col gap={5}>
          <Row items="end" gap={2}>
            <Heading
              type={HeadingTypes.H2}
              weight={FontWeight.BOLD}
              color={Color.success400}
              className="!tw-leading-snug"
            >
              {toCurrencyFormat(summary.paidAmount, '$')}
            </Heading>
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
              / {paidCount} {paidCount === 1 ? 'SAFE' : 'SAFEs'}
            </Text>
          </Row>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Invested amount
          </Text>
        </Col>

        <img src={goalFilledIcon} width={25} alt="Invested" />
      </Row>

      <Row justify="between" gap={20} className={styles.summaryBlock}>
        <Col gap={5}>
          <Row items="end" gap={8}>
            <Heading
              type={HeadingTypes.H2}
              weight={FontWeight.BOLD}
              color={Color.warning300}
              className="!tw-leading-snug"
            >
              {toCurrencyFormat(summary.unpaidAmount, '$')}
            </Heading>
            <Text
              type={TextTypes.BODY_SMALL}
              color={Color.primary400}
              clickable
              onClick={() =>
                navigator.toAllSafeNotes(companyId, 'unpaid', innerCompany?.id)
              }
              className="tw-underline tw-cursor-pointer"
            >
              {summary.unpaidCount}{' '}
              {summary.unpaidCount === 1 ? 'SAFE' : 'SAFEs'}
            </Text>
          </Row>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Pending
          </Text>
        </Col>

        <img src={goalFilledIcon} width={25} alt="Invested" />
      </Row>
    </div>
  )
}

export default InvestedCompanySummary
