import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { UsaStates } from 'usa-states'

import { ConditionalRender, UploadImage } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { FillCompanyImages } from '@/features/auth/assets'
import { EntrepreneurSchema } from '@/features/auth/schemas/AuthSchema'
import { UpdateCompanyValues } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { InvestorType } from '@/features/user'
import { VerificationStatus } from '@/features/user/enums'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  IModalWithCloseFn,
  Info,
  Modal,
  NumberInput,
  Row,
  Select,
  Spacer,
  TextInput,
  TextTypes
} from '@/packages/ui'

import styles from './EditCompanyModal.module.scss'

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const MODAL_ID = 'edit-company-modal'

const mapStateToProps = ({ user, profile }: StoreType) => ({
  refetchCompany: user.refetchCompany,
  updateCompany: profile.updateCompany,
  loading: profile.functionLoading.updateCompany
})

interface EditCompanyModalProps extends IModalWithCloseFn {
  company: ICompany
}

const EditCompanyModal: FC<
  EditCompanyModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { closeModal, company, refetchCompany, loading, updateCompany } = props

  const [logo, setLogo] = useState<any>(company.image)

  const { verificationStatus } = company

  const {
    watch,
    control,
    register,
    getValues,
    reset,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(EntrepreneurSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      ownerTitle: company.owner.position,
      name: company.name,
      stateOfIncorporation: company.stateOfIncorporation,
      address1: company.address?.address1,
      address2: company.address?.address2,
      city: company.address?.city,
      state: company.address?.state,
      zipCode: company.address?.zipCode,
      country: 'USA'
    }
  })

  useEffect(() => {
    reset()
  }, [])

  const onSubmit = async () => {
    const { name, stateOfIncorporation, ...address } = getValues()
    const query = {
      name,
      stateOfIncorporation,
      address,
      image: logo
    }

    await updateCompany({
      data: { companyId: company.id, values: query as UpdateCompanyValues },
      options: {
        onSuccess: async () => {
          await refetchCompany({ data: { id: company.id } })
          closeModal?.()
        }
      }
    })
  }

  const CompanyForm = (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Row justify="between" items="center" gap={50}>
          <Heading>Company info</Heading>
        </Row>

        <Spacer size={24} />
        <Form schema={EntrepreneurSchema}>
          <FormItem errors={errors.name?.message}>
            <TextInput
              disabled={verificationStatus === VerificationStatus.Verified}
              label={
                company.investorType === InvestorType.CORPORATE
                  ? 'Company name'
                  : 'Name'
              }
              placeholder="Company name"
              {...register('name')}
              invalid={FormHelper.isFieldInvalid('name', errors)}
            />
          </FormItem>

          <UploadImage
            required
            modalTitle="Upload Company's logo"
            description="Showcase your company logo on your profile. You can always change this later in settings."
            label="Company logo"
            caption="Upload Logo (PNG or JPG)"
            image={logo}
            onImageSelect={setLogo}
          />

          <Row gap={18} className="tw-mt-15">
            <FormItem errors={errors.stateOfIncorporation?.message}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    withPortal
                    disabled={
                      verificationStatus === VerificationStatus.Verified
                    }
                    name="stateOfIncorporation"
                    label="State of Incorporation"
                    placeholder="State of Incorporation"
                    options={states}
                    value={value!}
                    toTopOnFocus
                    onChange={(val: string) => onChange(val)}
                  />
                )}
                name="stateOfIncorporation"
              />
            </FormItem>
          </Row>

          <Row gap={18}>
            <FormItem errors={errors.address1?.message}>
              <TextInput
                {...register('address1')}
                label="Address"
                placeholder="Address"
                invalid={FormHelper.isFieldInvalid('address1', errors)}
              />
            </FormItem>

            <FormItem>
              <TextInput
                {...register('address2')}
                label="Apartment, suite, etc."
                placeholder="Apartment, suite, etc."
              />
            </FormItem>
          </Row>

          <Row gap={18}>
            <FormItem errors={errors.city?.message}>
              <TextInput
                {...register('city')}
                label="City"
                placeholder="City"
                invalid={FormHelper.isFieldInvalid('city', errors)}
              />
            </FormItem>

            <FormItem errors={errors.state?.message}>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    withPortal
                    name="state"
                    label="State"
                    placeholder="State"
                    options={states}
                    value={value!}
                    toTopOnFocus
                    onChange={(val: string) => onChange(val)}
                  />
                )}
                name="state"
              />
            </FormItem>
          </Row>

          <Row items="center" gap={18}>
            <FormItem errors={errors.zipCode?.message}>
              <Controller
                control={control}
                name="zipCode"
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    value={value}
                    onValueChange={(val: NumberFormatValues) => {
                      onChange(val.value)
                    }}
                    label="Zip Code"
                    placeholder="Zip Code"
                    name="zipCode"
                    invalid={FormHelper.isFieldInvalid('zipCode', errors)}
                    thousandSeparator={false}
                  />
                )}
              />
            </FormItem>

            <FormItem errors={errors.country?.message}>
              <TextInput
                {...register('country')}
                disabled
                label="Country"
                tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
                placeholder="Country"
                invalid={FormHelper.isFieldInvalid('country', errors)}
              />
            </FormItem>
          </Row>
        </Form>

        <Button
          uppercase
          onClick={onSubmit}
          disabled={!watch('name') || loading || !logo}
          width="default"
          className={clsx(
            'tw-mt-[15px]',
            verificationStatus === VerificationStatus.Verified && 'tw-mb-40'
          )}
        >
          {loading ? 'Loading...' : 'Save & move on'}
        </Button>

        <ConditionalRender
          condition={verificationStatus === VerificationStatus.Verified}
        >
          <Info textType={TextTypes.BODY_SMALL} className={styles.info}>
            Your company is already verified. Editing some of fields is not
            allowed here
          </Info>
        </ConditionalRender>
      </div>
      <div className={styles.stepImage}>
        <img src={FillCompanyImages.EntrepreneurFillInfo} alt="" />
      </div>
    </div>
  )

  return (
    <Modal
      id={MODAL_ID}
      visible
      size="lg"
      setVisible={closeModal}
      className={styles.modalStyles}
    >
      {CompanyForm}
    </Modal>
  )
}

export default withStore(mapStateToProps)(EditCompanyModal)
