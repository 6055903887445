import React, { FC, useMemo } from 'react'

import { StoreType, withStore } from '@/common/store'
import { ISafeNote, SafeNoteStatus, ViewSafeAs } from '@/features/safe-note'
import { SafeNoteActionsList } from '@/features/safe-note/enums'
import {
  DeleteSafeModal,
  SendSafeReminderModal
} from '@/features/safe-note/modals'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Disabled,
  FontWeight,
  Row,
  Text,
  TextTypes,
  Tooltip
} from '@/packages/ui'
import { useNavigator } from '@/router'

import Icons from './icons'

interface SafeNoteActionsProps {
  safeNote: ISafeNote
}

interface IOptionItem {
  id: SafeNoteActionsList
  disabled: boolean
  label: string
  icon: JSX.Element
  tooltipDisabled?: boolean
}

const mapStateToProps = ({ safeNote }: StoreType) => ({
  remove: safeNote.delete,
  deleteLoading: safeNote.functionLoading.delete,
  markAsPaid: safeNote.markAsPaid,
  downloadPdf: safeNote.downloadPdf
})

const SafeNoteActions: FC<
  SafeNoteActionsProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { safeNote, remove, deleteLoading, markAsPaid, downloadPdf } = props

  const reminderSentModal = useModal<boolean>(false)
  const navigate = useNavigator()

  const { visible, openModal, closeModal } = useModal<'delete' | 'reminder'>()

  const isDraft = safeNote.status === SafeNoteStatus.DRAFT

  const options: IOptionItem[] = useMemo(() => {
    const result = [
      {
        id: SafeNoteActionsList.View,
        disabled: false,
        label: 'View SAFE',
        tooltipDisabled: false,
        icon: isDraft ? (
          <Text
            type={TextTypes.BODY_SMALL}
            color={Color.primary400}
            weight={FontWeight.SEMIBOLD}
            className="tw-whitespace-nowrap !tw-flex !tw-items-center"
            clickable
          >
            Go to editing
            <SharedIcons.Arrow className="tw-ml-4" />
          </Text>
        ) : (
          <Icons.ViewSafe color={Color.neutral500} />
        )
      }
    ]

    if (safeNote.viewAs === ViewSafeAs.SENDER) {
      result.push({
        id: SafeNoteActionsList.Delete,
        disabled: safeNote.status === SafeNoteStatus.SIGNED || safeNote.paid,
        label: 'Delete',
        tooltipDisabled: false,
        icon: <Icons.DeleteSafe color={Color.neutral500} />
      })
    }

    if (safeNote.viewAs === ViewSafeAs.SENDER && !isDraft) {
      result.push(
        {
          id: SafeNoteActionsList.Remind,
          disabled: safeNote.status !== SafeNoteStatus.SENT,
          label: 'Send reminder',
          tooltipDisabled: false,
          icon: <Icons.SendReminder size={22} color={Color.neutral500} />
        },
        {
          id: SafeNoteActionsList.ChangePaymentStatus,
          disabled: safeNote.paid || safeNote.status !== SafeNoteStatus.SIGNED,
          label: 'Mark as paid',
          tooltipDisabled: false,
          icon: <Icons.ChangeInvestmentStatus color={Color.neutral500} />
        }
      )
    }

    if (!isDraft) {
      result.push({
        id: SafeNoteActionsList.Download,
        disabled: false,
        label: 'Download SAFE as PDF',
        tooltipDisabled: false,
        icon: <SharedIcons.Download size={22} color={Color.neutral500} />
      })
    }

    if (safeNote.viewAs === ViewSafeAs.RECIPIENT) {
      result.push({
        id: SafeNoteActionsList.MakePayment,
        disabled: true,
        label: 'Make payment (coming soon...)',
        tooltipDisabled: false,
        icon: <Icons.MakeInvestment color={Color.neutral500} />
      })
    }

    return result
  }, [safeNote])

  const onDeleteSafeNote = (safeNoteId: string, message: string) => {
    remove({
      data: {
        id: safeNote.id,
        message
      }
    })
  }

  const onClick = (option: IOptionItem) => {
    if (option.disabled) return

    switch (option.id) {
      case SafeNoteActionsList.View: {
        if (safeNote.status === SafeNoteStatus.DRAFT) {
          navigate.toCreateSafeNote(
            safeNote.senderCompany.id,
            {
              draftId: safeNote.id
            },
            { openInNewTab: true }
          )
        } else {
          navigate.toViewSafeNote(
            safeNote.id,
            {
              redirectCompanyId:
                safeNote.viewAs === ViewSafeAs.SENDER
                  ? safeNote.senderCompanyId
                  : (safeNote.recipientCompanyId as string)
            },
            { openInNewTab: true }
          )
        }

        break
      }
      case SafeNoteActionsList.Delete: {
        openModal('delete')
        break
      }
      case SafeNoteActionsList.Remind: {
        openModal('reminder')
        break
      }
      case SafeNoteActionsList.Download: {
        downloadPdf({
          data: {
            safeNoteId: safeNote.id,
            fileName: `MySAFEnotes ${safeNote.recipient.fullName}`
          }
        })
        break
      }
      case SafeNoteActionsList.ChangePaymentStatus: {
        markAsPaid({ data: { id: safeNote.id } })
        break
      }
      default: {
        break
      }
    }
  }

  return (
    <Row items="center" gap={10} className="!tw-pr-16">
      {visible === 'delete' && (
        <DeleteSafeModal
          closeModal={closeModal}
          safeNote={safeNote}
          loading={deleteLoading}
          onDelete={onDeleteSafeNote}
        />
      )}

      {visible === 'reminder' && (
        <SendSafeReminderModal
          onSent={() => {
            closeModal()
            reminderSentModal.openModal()
          }}
          id={safeNote.id}
          name={safeNote.recipient?.fullName as string}
          img={safeNote?.recipient?.image}
          closeModal={closeModal}
        />
      )}

      {options.map((option) => (
        <Tooltip
          arrow
          className="!tw-p-10"
          key={option.id}
          placement="bottom"
          content={option.label}
          disabled={option.tooltipDisabled}
        >
          <div>
            <Disabled
              disabled={option.disabled}
              onClick={() => onClick(option)}
            >
              {option.icon}
            </Disabled>
          </div>
        </Tooltip>
      ))}
    </Row>
  )
}

export default withStore(mapStateToProps)(SafeNoteActions)
