import clsx from 'clsx'
import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import NoteIcon from '@/features/dashboard/assets/images/no-safe-notes.png'
import { Color } from '@/packages/palette'
import {
  Button,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './NoItems.module.scss'

interface NoItemsProps {
  img?: string
  title?: string
  imgWidth?: number
  description?: string
  action?: string
  className?: string
  onActionClick?: () => void
}

const DEFAULT_TITLE = 'You don’t have any SAFE Notes'
const DEFAULT_DESCRIPTION =
  'We can help with that one. Use our SAFE note builder and easily create one in minutes.'

const NoItems: FC<NoItemsProps> = ({
  action,
  onActionClick,
  img = NoteIcon,
  imgWidth = 90,
  className,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION
}) => (
  <div className={clsx(styles.wrapper, className)}>
    <img src={img} alt="Note Icon" width={imgWidth} />
    <Heading type={HeadingTypes.H3} color={Color.neutral300}>
      {title}
    </Heading>
    <Text
      align={TextAlign.CENTER}
      type={TextTypes.BODY_DEFAULT}
      className={styles.description}
      color={Color.neutral300}
    >
      {description}
    </Text>

    <ConditionalRender condition={!!action}>
      <Button
        uppercase
        appearance="primary"
        width="default"
        onClick={onActionClick}
        className="tw-mt-[26px]"
      >
        {action}
      </Button>
    </ConditionalRender>
  </div>
)

export default NoItems
