import React, { FC, useCallback, useMemo } from 'react'

import { Table } from '@/common/components'
import { NoPermissionsTooltip, SafeNoteButton } from '@/features/dashboard'
import { SAFE_NOTES_TABS } from '@/features/dashboard/enums'
import {
  safeColumns,
  termSheetColumns
} from '@/features/dashboard/views/SafeNoteList/columns'
import { useModalContext } from '@/features/modals'
import { ICompany } from '@/features/profile/types'
import { containsMissedFields } from '@/features/profile/utils'
import { ISafeNote, SafeNoteStatus } from '@/features/safe-note'
import { SelectSafeRecipientModal } from '@/features/safe-note/modals'
import { ITermSheet } from '@/features/term-sheet/api/types'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { NothingFound } from '@/packages/ui'
import { HandleActionFnParams } from '@/packages/ui/table'
import { TableTab } from '@/packages/ui/table/Table'
import { BASE_TABLE_ACTIONS, RowsData } from '@/packages/ui/table/types'
import { useNavigator } from '@/router'

import NoSafeNotesBlock from '../../../safe-note/components/NoSafeNotesBlock/NoSafeNotesBlock'
import styles from './SafeNoteList.module.scss'

type SafeNotesProps = {
  data: ISafeNote[]
  role: ProfileTypes
  withoutTabs?: boolean
  title?: string | JSX.Element
  emailVerified?: boolean
  permission: TeamMemberPermission
  onDelete?: (id: string) => void
  currentCompany: ICompany
  termSheets: ITermSheet[]
}

const SafeNoteList: FC<SafeNotesProps> = ({
  data,
  role,
  title = 'SAFE Notes',
  withoutTabs,
  termSheets,
  emailVerified,
  permission,
  onDelete,
  currentCompany
}: SafeNotesProps) => {
  const navigate = useNavigator()

  const {
    fillCompanyInfoModal,
    fillCompanyInfoForTermSheetModal,
    verifyEmailModal,
    dontHavePermissionModal
  } = useModalContext()

  const { visible, closeModal } = useModal(false)
  const isEntrepreneur = ProfileTypes.ENTREPRENEUR === role

  // const { companyInfoModal } = useModalContext()

  const tabs: TableTab[] = useMemo(
    () => [
      {
        accessor: SAFE_NOTES_TABS.ALL,
        title: 'All SAFE Notes',
        filterFunc: (rows: RowsData) => rows
      },
      {
        accessor: SAFE_NOTES_TABS.UNPAID,
        title: 'Unpaid',
        filterFunc: (rows: RowsData) => rows?.filter((item) => !item.paid)
      },
      ...(isEntrepreneur
        ? [
            {
              accessor: SAFE_NOTES_TABS.DRAFT,
              title: 'Drafts',
              filterFunc: (rows: RowsData) =>
                rows?.filter((item) => item.status === SafeNoteStatus.DRAFT)
            }
          ]
        : [])
      // {
      //   accessor: SAFE_NOTES_TABS.TERM_SHEET,
      //   title: 'Term Sheets',
      //   filterFunc: () => termSheets || []
      // }
    ],
    [termSheets]
  )

  const handleCreateSafe = (type: 'safe-note' | 'term-sheet') => {
    if (permission !== TeamMemberPermission.CREATE) {
      dontHavePermissionModal.openModal()
      return
    }

    if (!emailVerified) {
      verifyEmailModal.openModal()
      return
    }

    if (containsMissedFields(currentCompany)) {
      if (type === 'safe-note') {
        fillCompanyInfoModal.openModal()
      } else {
        fillCompanyInfoForTermSheetModal.openModal()
      }
      return
    }

    if (type === 'safe-note') {
      // openSelectRecipientModal()
      navigate.toCreateSafeNote(currentCompany.id)
      return
    }

    navigate.toCreateTermSheet(currentCompany.id)
  }

  const handleAction = useCallback(
    ({ action, row }: HandleActionFnParams) => {
      switch (action) {
        case BASE_TABLE_ACTIONS.DELETE: {
          onDelete?.(row.id)
          break
        }

        case BASE_TABLE_ACTIONS.LINK_CLICK: {
          navigate.toViewSafeNote(row.id)
          break
        }

        default:
          break
      }
    },
    [onDelete]
  )

  const columns = (tab: string | undefined) =>
    tab === SAFE_NOTES_TABS.TERM_SHEET
      ? termSheetColumns(isEntrepreneur)
      : safeColumns(!isEntrepreneur)

  return (
    <div className={styles.tableWrapper}>
      {visible && (
        <SelectSafeRecipientModal
          companyId={currentCompany.id}
          closeModal={closeModal}
        />
      )}

      <Table
        withRowClick
        title={title}
        searchFields={['username']}
        columns={columns}
        data={data}
        customActions={
          <NoPermissionsTooltip permission={permission}>
            <SafeNoteButton
              hidden={currentCompany.type === ProfileTypes.ANGEL}
              onClick={handleCreateSafe}
            />
          </NoPermissionsTooltip>
        }
        handleAction={handleAction}
        tabs={withoutTabs ? undefined : tabs}
        noResults={{
          byDefault: (
            <NoSafeNotesBlock
              onClick={handleCreateSafe}
              isEntrepreneur={isEntrepreneur}
            />
          ),
          afterSearch: <NothingFound isSearch />
        }}
      />
    </div>
  )
}

export default SafeNoteList
