import React, { FC } from 'react'

import { ConditionalRender } from '@/common/components'
import {
  PaymentBridge,
  PaymentBridgeChildrenProps,
  PaymentMethodData,
  PaymentOptions,
  PaymentStatus,
  PaymentType
} from '@/features/payment'
import {} from '@/features/payment/components/BankItem/BankItem'
import {
  Button,
  Col,
  Heading,
  HeadingTypes,
  IFile,
  Row,
  Spacer,
  Text
} from '@/packages/ui'

import { BankItem, IBankAccountItem } from '../BankItem'
import styles from './PaymentModule.module.scss'

export interface PaymentModuleProps {
  cashAmount?: number
  platformFee: number
  stripeFee: number
  onPay?: (
    data: PaymentBridgeChildrenProps
  ) => (data: PaymentMethodData) => void
  onPayLater?: () => void
  onSelectPaymentMethod?: (method: PaymentType) => void
  paymentType: PaymentType
  status?: PaymentStatus
  details?: any
  withRecordPayment?: boolean
  setRecordPaymentFiles?: (files: IFile[] | undefined) => void
  onVerifyBankAccount?: () => void
  bankAccount?: IBankAccountItem | null
}

const PaymentModule: FC<PaymentModuleProps> = ({
  platformFee,
  cashAmount = 0,
  onPay,
  onPayLater,
  onSelectPaymentMethod,
  paymentType,
  status,
  stripeFee,
  details,
  setRecordPaymentFiles,
  withRecordPayment = true,
  onVerifyBankAccount,
  bankAccount
}) => (
  <PaymentBridge
    initialDetails={details}
    payAmount={cashAmount + platformFee}
    initialStatus={status}
  >
    {(paymentBridgeProps: PaymentBridgeChildrenProps) => (
      <div className={styles.container}>
        {/* <PaymentDetails */}
        {/*   cashAmount={cashAmount} */}
        {/*   platformFee={platformFee} */}
        {/*   stripeFee={stripeFee} */}
        {/* /> */}

        <Col items="center" gap={8}>
          <Heading type={HeadingTypes.H4}>Payment Processing</Heading>
          <Text>
            We are currently working on our investment processing system. You
            will be able to make investments using your debit or credit card in
            the near future. For now, you can ask the entrepreneur to mark this
            SAFE as &ldquo;paid&rdquo;, once you&apos;ve made the investment and
            they receive the funds.
          </Text>
        </Col>

        <Spacer size={24} />

        <PaymentOptions
          title="Select investment method"
          withRecordPayment={withRecordPayment}
          onPayLater={onPayLater}
          onPay={onPay?.(paymentBridgeProps)}
          cashAmount={cashAmount}
          onSelect={onSelectPaymentMethod}
          setRecordPaymentFiles={setRecordPaymentFiles}
        />

        {!!bankAccount && paymentType === PaymentType.BANK_TRANSFER && (
          <BankItem
            name={bankAccount.name}
            last4Digits={bankAccount.last4Digits}
          />
        )}

        <Row justify="end" className="tw-mt-24">
          <ConditionalRender
            condition={
              !bankAccount?.name && paymentType === PaymentType.BANK_TRANSFER
            }
            fallbackElement={
              <Button
                width="default"
                uppercase
                onClick={onPay?.(paymentBridgeProps) as any}
              >
                Complete Safe
              </Button>
            }
          >
            <Button
              width="default"
              uppercase
              onClick={onVerifyBankAccount}
              disabled={paymentBridgeProps.verifyBankAccountLoading}
            >
              Verify bank account
            </Button>
          </ConditionalRender>
        </Row>
      </div>
    )}
  </PaymentBridge>
)

export default PaymentModule
