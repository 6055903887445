import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import screenfull from 'screenfull'

interface FullScreenContextValue {
  isFullScreenActive: boolean
  toggleFullScreen: () => void
}

const FullScreenContext = React.createContext<FullScreenContextValue>(
  {} as FullScreenContextValue
)

interface IProps {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  elementId?: string
}

const FullScreenContextProvider: FC<PropsWithChildren<IProps>> = (props) => {
  const { children, active, setActive, elementId } = props

  const toggle = useCallback(() => {
    if (active) {
      screenfull.exit()
    } else if (elementId) {
      const element = document.getElementById(elementId)

      screenfull.request(element || undefined)
    } else {
      screenfull.request()
    }
  }, [active])

  useEffect(() => {
    const syncState = () => {
      setActive(screenfull.isFullscreen)
    }

    screenfull.on('change', syncState)

    return () => {
      screenfull.off('change', syncState)
    }
  }, [setActive])

  const value = useMemo(
    () => ({ isFullScreenActive: active, toggleFullScreen: toggle }),
    [active, toggle]
  )

  return (
    <FullScreenContext.Provider value={value}>
      {children}
    </FullScreenContext.Provider>
  )
}

const useFullScreenContext = () => useContext(FullScreenContext)

export { FullScreenContextProvider, useFullScreenContext }
