import React, { FC, useState } from 'react'

import { SharedImages } from '@/assets/images'
import { Avatar, ModalFooter } from '@/common/components'
import { ToastService } from '@/common/services'
import { StoreType, useStore } from '@/common/store'
import reminderImg from '@/features/safe-note/assets/images/reminder-modal.png'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Row,
  Text,
  TextAlign,
  TextArea,
  TextTypes
} from '@/packages/ui'

import styles from './SendSafeReminderModal.module.scss'

const MODAL_ID = 'send-reminder-modal'

const SendSafeReminderModal: FC<
  IModalWithCloseFn & {
    name: string
    img?: string
    id: string
    portalId?: string
    onSent: () => void
  }
> = (props) => {
  const { closeModal, name, img, id, onSent, portalId = MODAL_ID } = props

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string | undefined>(undefined)

  const sendReminder = useStore(
    (store: StoreType) => store.safeNote.notifyToSign
  )

  const handleSendReminder = async () => {
    setLoading(true)

    try {
      await sendReminder({
        data: { id, reminderMessage: message }
      })

      onSent()
    } catch (e) {
      ToastService.showError('We faced some problems with sending reminders')
    }

    setLoading(false)
  }

  return (
    <Modal size="sm" id={portalId} visible setVisible={closeModal}>
      <Col items="center" gap={10}>
        <img src={reminderImg} alt="Give feedback" width={130} />

        <Heading type={HeadingTypes.H3} align={TextAlign.CENTER}>
          Send reminder to
        </Heading>

        <Row items="center" gap={6} className={styles.recipient}>
          <Avatar
            name={name}
            image={img}
            size={25}
            defaultImage={SharedImages.UserPlaceholder}
          />

          <Text
            type={TextTypes.BODY_SMALL}
            color={Color.neutral400}
            weight={FontWeight.SEMIBOLD}
            className="!tw-leading-snug"
          >
            {name}
          </Text>
        </Row>

        <Text
          type={TextTypes.BODY_DEFAULT}
          color={Color.neutral500}
          align={TextAlign.CENTER}
        >
          We’ll send this person a reminder to sign the SAFE.
        </Text>

        <div className="tw-self-stretch">
          <TextArea
            value={message}
            disabled={loading}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Shortly write your reminder"
          />
        </div>
      </Col>

      <ModalFooter
        className="tw-mt-20"
        leftButtonText="Cancel"
        rightButtonText={loading ? 'Loading...' : 'Send'}
        disabled={!message || loading}
        rightButtonActon={handleSendReminder}
        leftButtonAction={closeModal}
      />
    </Modal>
  )
}

export default SendSafeReminderModal
