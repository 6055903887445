import clsx from 'clsx'
import React, { FC, useState } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { SafesList } from '@/features/dashboard/modals/IncomingSafesModal/parts'
import { ISafeNote } from '@/features/safe-note'
import { IModalWithCloseFn, Loader, Modal, Row } from '@/packages/ui'

import styles from './IncomingSafesModal.module.scss'

const MODAL_ID = 'pending-safes-modal'

interface IncomingSafesModalProps extends IModalWithCloseFn {
  safes: ISafeNote[]
}

const mapStateToProps = ({ safeNote }: StoreType) => ({
  loading: safeNote.functionLoading.acceptSafeNote
})

const IncomingSafesModal: FC<
  IncomingSafesModalProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const { safes, loading, closeModal } = props

  const [activeSafe] = useState<ISafeNote | null>(
    safes.length > 0 ? safes[0] : null
  )
  const [step] = useState<'invites' | 'decline'>('invites')

  const currentSafe =
    safes.length === 1 ? safes[0] : safes?.find((p) => p.id === activeSafe?.id)

  if (!Array.isArray(safes) || !currentSafe) {
    return null
  }

  return (
    <Modal
      size="sm"
      id={MODAL_ID}
      visible
      setVisible={closeModal}
      className={clsx('!tw-p-0')}
      modalInnerClassName="tw-flex"
    >
      <ConditionalRender
        condition={!loading}
        fallbackElement={<Loader width="100%" height="300px" />}
      >
        <Row className="tw-w-full" justify="center">
          <div className={styles.safeContainer}>
            {step === 'invites' && (
              <SafesList
                id={currentSafe.id}
                mfn={currentSafe.mfn}
                senderCompany={currentSafe.senderCompany}
                amount={currentSafe.safeAmount}
                discount={currentSafe.discountRate}
                valuation={currentSafe.valuationCap}
                sender={currentSafe.sender}
              />
            )}
          </div>
        </Row>
      </ConditionalRender>
    </Modal>
  )
}

export default withStore(mapStateToProps)(IncomingSafesModal)
