import clsx from 'clsx'
import React, { FC, ReactNode, useEffect } from 'react'
import { useLockedBody } from 'usehooks-ts'

import { Portal } from '../../portal'
import { LottieLoader } from '../LottieLoader'
import styles from './FullScreenLoading.module.scss'

interface FullScreenLoadingProps {
  solidColor?: boolean
  loading?: boolean
  component?: ReactNode
  portalId?: string
}

const FullScreenLoading: FC<FullScreenLoadingProps> = ({
  solidColor,
  loading,
  portalId = 'fullscreen-loading',
  component = <LottieLoader />
}: FullScreenLoadingProps) => {
  const [, setLocked] = useLockedBody(false, 'root')

  useEffect(() => {
    setLocked(!!loading)

    if (!loading) {
      setTimeout(() => {
        setLocked(false)

        const body = document.querySelector('body')

        if (body) {
          body.style.overflow = ''
        }
      }, 100)
    }
  }, [loading])

  if (!loading) return <></>

  return (
    <Portal wrapperId={portalId}>
      <div className={clsx(styles.container, solidColor && styles.solidColor)}>
        {component}
      </div>
    </Portal>
  )
}

export default FullScreenLoading
