import clsx from 'clsx'
import { noop } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ConditionalRender, Search } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import {
  AngelInvestmentCompany,
  Breadcrumbs,
  DashboardCompanyHeader,
  DashboardSummaryGoal,
  DashboardSummarySafe,
  InvestedCompanySummary,
  SafeNoteList
} from '@/features/dashboard'
import noItemsImg from '@/features/dashboard/components/CompaniesList/images/no-companies.png'
import { useDashboardNavigationContext } from '@/features/dashboard/context'
import { ISummaryItem } from '@/features/profile/api/types'
import { defaultSummary } from '@/features/profile/constants'
import { ICompany } from '@/features/profile/types'
import { getPermission } from '@/features/profile/utils'
import { ISafeNote } from '@/features/safe-note'
import { TeamMemberPermission } from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  FontWeight,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  Loader,
  Row,
  Text,
  TextTypes
} from '@/packages/ui'
import { NoItems } from '@/packages/ui/table/views'
import { useNavigator } from '@/router'

import styles from './DashboardAngelView.module.scss'

const mapStateToProps = ({
  profile,
  user,
  safeNote,
  termSheet
}: StoreType) => ({
  userId: user.me?.id,
  fetchSafeNotes: safeNote.fetchAll,
  fetchCompanySenders: safeNote.fetchCompanySenders,
  safeNotesList: safeNote.safeNoteList,
  investmentCompanies: safeNote.investmentCompanies,
  fetchSingleSummary: profile.fetchSingleSummary,
  fetchSummaries: profile.fetchSummaries,
  fetchTermSheets: termSheet.fetchAll,
  termSheetsList: termSheet.termSheetsList,
  downloadPdfLoading: safeNote.functionLoading.downloadPdf
})

const DashboardAngelView: FC<
  { currentCompany: ICompany } & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    userId,
    fetchSafeNotes,
    fetchCompanySenders,
    safeNotesList,
    investmentCompanies,
    fetchSingleSummary,
    fetchSummaries,
    currentCompany,
    fetchTermSheets,
    downloadPdfLoading,
    termSheetsList
  } = props
  const { setSelectedProfile } = useDashboardNavigationContext()
  const navigate = useNavigator()

  const { innerCompanyId } = useParams()

  const [companySendersSummary, setCompanySendersSummary] = useState<{
    [key: string]: ISummaryItem
  }>({})
  const [summary, setSummary] = useState<ISummaryItem>(() => defaultSummary)
  const [search, setSearch] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const filteredCompanies = useMemo(() => {
    if (!search) return investmentCompanies

    return investmentCompanies.filter((company) =>
      company.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [investmentCompanies, search])

  const companyId = currentCompany.id

  const innerCompany = investmentCompanies?.find?.(
    (company) => company.id === innerCompanyId
  )

  const permission = useMemo(
    () => getPermission(userId, currentCompany),
    [userId, currentCompany]
  )

  const goToProfileDashboard = () => {
    setSelectedProfile(ProfileTypes.ANGEL)
    navigate.toDashboard()
  }

  const toUpperLevel = () => {
    setSelectedProfile(ProfileTypes.ANGEL)
    navigate.toDashboardCompany(companyId)
  }

  const fetchData = async () => {
    setLoading(() => true)

    try {
      if (!innerCompanyId) {
        const summaryResponse = await fetchSingleSummary({
          data: { id: companyId }
        })

        setSummary(summaryResponse?.summary || defaultSummary)

        await fetchCompanySenders({
          data: { angelCompanyId: companyId }
        })

        const sendersSummaries = await fetchSummaries({
          data: { angelCompanyId: companyId }
        })

        if (Array.isArray(sendersSummaries)) {
          setCompanySendersSummary(
            sendersSummaries.reduce((acc, next) => {
              acc[next.id] = next.summary
              return acc
            }, {})
          )

          setLoading(() => false)
          return
        }

        setCompanySendersSummary({})

        setLoading(() => false)
        return
      }

      if (!investmentCompanies.length) {
        await fetchCompanySenders({
          data: { angelCompanyId: companyId }
        })
      }

      await fetchSafeNotes({
        data: {
          entrepreneurCompanyId: innerCompanyId,
          angelCompanyId: companyId
        }
      })

      await fetchTermSheets({
        data: {
          entrepreneurCompanyId: innerCompanyId,
          angelCompanyId: companyId
        }
      })

      setLoading(() => false)
    } catch {
      setLoading(() => false)
    }
  }

  useEffect(() => {
    if (!!innerCompanyId) {
      const customSummary = (safeNotesList || []).reduce(
        (_result: ISummaryItem, nextSafe: ISafeNote) => {
          const newResult = cloneDeep(_result)

          if (nextSafe.paid) {
            newResult.paidAmount += nextSafe.safeAmount
          } else {
            newResult.unpaidAmount += nextSafe.safeAmount
            newResult.unpaidCount += 1
          }

          newResult.totalSafeCount += 1

          return newResult
        },
        defaultSummary
      )

      setSummary(() => customSummary)
    }
  }, [safeNotesList])

  useEffect(() => {
    fetchData()
  }, [companyId, innerCompanyId])

  if (loading) {
    return <Loader width="100%" />
  }

  const OuterLevel = (
    <>
      <Row items="center" justify="between" gap={20}>
        <Row items="center" gap={14}>
          <Heading
            type={HeadingTypes.H3}
            weight={FontWeight.SEMIBOLD}
            color={Color.neutral500}
          >
            Companies I’ve Invested In
          </Heading>
          <div className={styles.spacer} />
          <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
            {filteredCompanies.length}{' '}
            {filteredCompanies.length === 1 ? 'company' : 'companies'}
          </Text>
        </Row>

        <div className={styles.sortingAndSearch}>
          {/* <Select */}
          {/*   small */}
          {/*   name="sort" */}
          {/*   placeholder="Sort By" */}
          {/*   value={sort} */}
          {/*   options={sortBy} */}
          {/*   onChange={(value: string) => setSort(value)} */}
          {/* /> */}
          <Search
            search={search}
            onSearchChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </Row>

      <ConditionalRender
        condition={!!filteredCompanies.length}
        fallbackElement={
          <NoItems
            img={noItemsImg}
            title="No companies were found"
            description="You didn’t invest into companies with entered name"
          />
        }
      >
        <div className={styles.investmentsCompanies}>
          {filteredCompanies.map((company) => (
            <AngelInvestmentCompany
              key={company.id}
              name={company.name}
              logo={company.image}
              owner={company.owner.fullName}
              onClick={() =>
                navigate.toDashboardInnerCompany(
                  companyId,
                  company.id.toString()
                )
              }
              paidSafes={
                (companySendersSummary[company.id]?.totalSafeCount || 0) -
                (companySendersSummary[company.id]?.unpaidCount || 0)
              }
              unpaidSafes={companySendersSummary[company.id]?.unpaidCount || 0}
            />
          ))}
        </div>
      </ConditionalRender>
    </>
  )

  // useEffect(() => {
  // if (!!innerCompanyId && safeNotesList?.length) {
  //   setInnerCompany(safeNotesList.filter((safeNote) => safeNote.senderCompany.id === innerCompanyId))
  // }
  // }, [innerCompanyId, safeNotesList])

  // useEffect(() => {
  //   if (innerCompanyId && !innerCompany) {
  //     navigate.toDashboardCompany(companyId)
  //   }
  // }, [innerCompany, innerCompanyId])

  return (
    <div className="tw-w-full">
      <FullScreenLoading loading={downloadPdfLoading} />
      <div className={clsx(!!innerCompanyId && styles.blueBg)}>
        <div className={clsx(!!innerCompanyId && styles.moduleWrapper)}>
          <Breadcrumbs
            onInnerClick={toUpperLevel}
            innerCompany={innerCompany?.name}
            type={currentCompany.type}
            company={currentCompany.name}
            onTypeClick={goToProfileDashboard}
          />

          <ConditionalRender
            condition={!innerCompanyId}
            fallbackElement={
              <InvestedCompanySummary
                companyId={currentCompany.id}
                summary={summary}
                innerCompany={innerCompany}
              />
            }
          >
            <DashboardCompanyHeader company={currentCompany} />
            <section className={clsx(styles.summarySection)}>
              <DashboardSummarySafe
                isEmpty={!summary.totalSafeCount}
                type="completed"
                count={summary.totalSafeCount}
                amount={summary.paidAmount + summary.unpaidAmount}
                role={ProfileTypes.ANGEL}
                permission={permission}
                onClick={() => navigate.toAllSafeNotes(companyId, 'all')}
              />

              <DashboardSummarySafe
                isEmpty={!summary.totalSafeCount}
                type="incomplete"
                count={summary.unpaidCount}
                amount={summary.unpaidAmount}
                role={ProfileTypes.ANGEL}
                permission={permission}
                onClick={() => navigate.toAllSafeNotes(companyId, 'unpaid')}
              />

              <DashboardSummaryGoal
                savedGoal={0}
                columnMode
                safesAmount={summary.paidAmount + summary.unpaidAmount}
                pendingAmount={summary.unpaidAmount}
                role={ProfileTypes.ANGEL}
                permission={permission}
                updateGoal={noop}
                pendingCount={summary.unpaidCount}
                openModal={noop}
              />
            </section>
          </ConditionalRender>
        </div>
      </div>

      <div className={clsx(!!innerCompanyId && styles.moduleWrapper)}>
        <ConditionalRender
          condition={!!innerCompanyId}
          fallbackElement={OuterLevel}
        >
          <SafeNoteList
            termSheets={termSheetsList}
            permission={TeamMemberPermission.VIEW}
            currentCompany={currentCompany}
            data={safeNotesList}
            role={ProfileTypes.ANGEL}
          />
        </ConditionalRender>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(DashboardAngelView)
