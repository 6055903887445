import React from 'react'

import { ISafeNote, SafeNoteActions } from '@/features/safe-note'
import {
  ITermSheet,
  TermSheetSafeProgress,
  TermSheetStatus
} from '@/features/term-sheet/api/types'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Col,
  FontWeight,
  Text,
  TextTypes,
  Tooltip
} from '@/packages/ui'
import {
  BadgeColumn,
  CompanyColumn,
  DiscountAndCap,
  MoneyColumn,
  SAFE,
  TermSheetActionsColumn,
  TermSheetRecipients
} from '@/packages/ui/table/columns'
import { TableColumn, TableColumnType } from '@/packages/ui/table/types'

import styles from './SafeNoteList.module.scss'

const statusCombinations = {
  draft: BadgeType.DEFAULT,
  sent: BadgeType.WARNING,
  declined: BadgeType.ERROR,
  signed: BadgeType.INFO
}

const termSheetCombinations = {
  [TermSheetStatus.Rejected]: BadgeType.ERROR,
  [TermSheetStatus.Accepted]: BadgeType.SUCCESS,
  [TermSheetStatus.Pending]: BadgeType.WARNING
}

export const safeColumns = (viewAsAngel?: boolean): TableColumn[] => [
  {
    title: 'Sent to',
    accessor: 'author',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => <SAFE row={row} />
  },
  {
    title: 'Date sent',
    accessor: 'createdAt',
    type: TableColumnType.DATE
  },
  {
    title: 'Amount',
    accessor: 'safeAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} withApproveState />
  },
  {
    title: 'Discount | Cap',
    accessor: 'discount',
    tooltip: () => (
      <Col gap={15}>
        <Col>
          <Text
            weight={FontWeight.MEDIUM}
            type={TextTypes.BODY_SMALL}
            color={Color.primary500}
          >
            Discount
          </Text>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Allows investors to convert their SAFE to company stock at a
            discounted price
          </Text>
        </Col>

        <Col>
          <Text
            weight={FontWeight.MEDIUM}
            type={TextTypes.BODY_SMALL}
            color={Color.primary500}
          >
            Valuation Cap
          </Text>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Allows investor to convert their SAFE to company stock at the
            predetermined Cap, even if the company’s valuation increases
          </Text>
        </Col>

        <Col>
          <Text
            weight={FontWeight.MEDIUM}
            type={TextTypes.BODY_SMALL}
            color={Color.primary500}
          >
            Most Favored Nation (MFN)
          </Text>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Allows investor one opportunity to match their terms to terms issued
            to a future investor
          </Text>
        </Col>
      </Col>
    ),
    type: TableColumnType.CUSTOM,
    Component: DiscountAndCap
  },
  {
    title: 'Status',
    accessor: 'status',
    tooltip: () => (
      <Col gap={15}>
        <Col gap={4}>
          <Badge size="small" lightMode type={BadgeType.WARNING}>
            Sent
          </Badge>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            SAFE was sent. Investor hasn’t accepted it yet
          </Text>
        </Col>

        <Col gap={4}>
          <Badge size="small" lightMode type={BadgeType.SUCCESS}>
            Accepted
          </Badge>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Investor accepted the SAFE. Next, they need to add their signature
          </Text>
        </Col>

        <Col gap={4}>
          <Badge size="small" lightMode type={BadgeType.ERROR}>
            Declined
          </Badge>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Investor declined the SAFE
          </Text>
        </Col>

        <Col gap={4}>
          <Badge size="small" lightMode type={BadgeType.INFO}>
            Signed
          </Badge>
          <Text type={TextTypes.BODY_SMALL} color={Color.neutral400}>
            Congratulations! SAFE is signed
          </Text>
        </Col>
      </Col>
    ),
    type: TableColumnType.CUSTOM,
    Component: (props) => (
      <BadgeColumn {...props} statusesCombinations={statusCombinations} />
    )
  },
  {
    title: 'Investment',
    accessor: 'paid',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => (
      <Badge lightMode type={row.paid ? BadgeType.SUCCESS : BadgeType.ERROR}>
        {row.paid ? 'Paid' : 'Not paid'}
      </Badge>
    )
  },
  {
    title: 'Comment',
    accessor: 'comment',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: { row: any }) => {
      const TextInfo = (
        <Text
          type={TextTypes.BODY_SMALL}
          color={Color.neutral500}
          className={styles.comment}
        >
          {row.comment || '-'}
        </Text>
      )

      return row.comment ? (
        <Tooltip interactive content={row.comment}>
          <div>{TextInfo}</div>
        </Tooltip>
      ) : (
        TextInfo
      )
    }
  },
  {
    title: 'Actions',
    accessor: 'options',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: { row: ISafeNote }) => (
      <SafeNoteActions safeNote={row} />
    )
  }
]

const safeProgressMapper = {
  [TermSheetSafeProgress.Awaiting]: 'Awaiting SAFE',
  [TermSheetSafeProgress.Created]: 'SAFE created',
  [TermSheetSafeProgress.Paid]: 'SAFE paid'
}

export const termSheetColumns = (isEntrepreneur: boolean): TableColumn[] => [
  {
    title: 'Company',
    accessor: 'senderCompany',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => <CompanyColumn company={row.senderCompany} />
  },
  {
    title: 'Date sent',
    accessor: 'createdAt',
    type: TableColumnType.DATE
  },
  {
    title: 'Round amount',
    accessor: 'roundAmount',
    type: TableColumnType.CUSTOM,
    Component: (props) => <MoneyColumn {...props} />
  },
  {
    title: 'Discount | Cap',
    accessor: 'discount',
    type: TableColumnType.CUSTOM,
    Component: DiscountAndCap
  },
  ...(isEntrepreneur
    ? [
        {
          title: 'Recipients',
          accessor: 'recipients',
          type: TableColumnType.CUSTOM,
          Component: ({ row }: any) => (
            <TermSheetRecipients recipients={row.recipients || []} />
          )
        } as TableColumn
      ]
    : [
        {
          title: 'Status',
          accessor: 'status',
          type: TableColumnType.CUSTOM,
          Component: ({ row, accessor }: { row: any; accessor: string }) => (
            <BadgeColumn
              accessor={accessor}
              row={row.termSheetUser}
              defaultType={BadgeType.WARNING}
              defaultValue="Pending"
              statusesCombinations={termSheetCombinations}
            />
          )
        } as TableColumn,
        {
          title: 'Comment',
          accessor: 'comment',
          type: TableColumnType.CUSTOM,
          Component: ({ row }: { row: any }) => {
            const TextInfo = (
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral500}
                className={styles.comment}
              >
                {row.termSheetUser.comment || '-'}
              </Text>
            )

            return row.termSheetUser.comment ? (
              <Tooltip interactive content={row.termSheetUser.comment}>
                <div>{TextInfo}</div>
              </Tooltip>
            ) : (
              TextInfo
            )
          }
        } as TableColumn,
        {
          title: 'SAFE progress',
          accessor: 'safeProgress',
          type: TableColumnType.CUSTOM,
          Component: ({ row }: { row: ITermSheet }) => (
            <Text type={TextTypes.BODY_SMALL} color={Color.neutral500}>
              {row.termSheetUser.status === TermSheetStatus.Rejected
                ? '-'
                : safeProgressMapper[row.termSheetUser.safeProgress]}
            </Text>
          )
        } as TableColumn
      ]),
  {
    title: 'Actions',
    accessor: 'actions',
    type: TableColumnType.CUSTOM,
    Component: ({ row }: any) => (
      <TermSheetActionsColumn isEntrepreneur={isEntrepreneur} row={row} />
    )
  }
]
