import { Color } from '@/packages/palette'

export const SAFE_NOTE_TEMPLATE_ID = 'safe-note-template-id'

export const SAFE_NOTE_WRAPPER_ID = 'safe-note-wrapper-id'

export const SAFE_NOTE_TEMPLATE_ANCHORS = {
  COMPANY: 'safe-company-anchor',
  INVESTOR: 'safe-recipient-anchor',
  AMOUNT: 'safe-amount-anchor',
  DISCOUNT_RATE: 'safe-discount-rate-anchor',
  VALUATION_CAP: 'safe-valuation-cap-anchor',
  MY_SIGNATURE: 'safe-my-signature-anchor',
  THEIR_SIGNATURE: 'safe-their-signature-anchor',
  SIGNATURES: 'safe-note-signatures'
}

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'

export const PLATFORM_FEE = 4
export const PROCESSING_FEE = 4

export const highlightsColorMapper = {
  mfn: {
    active: Color.success500,
    inactive: Color.success400
  },
  discountRate: {
    active: Color.primary500,
    inactive: Color.primary300
  },
  valuationCap: {
    active: Color.error500,
    inactive: Color.error400
  },
  helpSetTerms: {
    active: '#3D0880',
    inactive: '#6d27c5'
  }
}
