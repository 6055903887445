import clsx from 'clsx'
import Lottie from 'lottie-react'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import { LottieConfetti } from '@/assets/lottie'
import { Avatar, HeaderBar } from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { toCurrencyFormat } from '@/common/utils'
import { ICompany } from '@/features/profile/types'
import { ISafeNote } from '@/features/safe-note'
import starsImg from '@/features/safe-note/assets/images/stars.png'
import transferArrowsImg from '@/features/safe-note/assets/images/transfer-arrows.svg'
import { IUser } from '@/features/user'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  Button,
  Col,
  FontWeight,
  FullScreenLoading,
  Heading,
  HeadingTypes,
  Row,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'
import { useNavigator } from '@/router'

import styles from './SafePaymentSummary.module.scss'

interface PaymentSummaryProps {
  safeNote: ISafeNote
}

const mapStateToProps = ({ profile, safeNote }: StoreType) => ({
  fetchOne: profile.fetchOne,
  downloadPdf: safeNote.downloadPdf,
  fetchOneLoading: profile.functionLoading.fetchOne,
  downloadPdfLoading: safeNote.functionLoading.downloadPdf
})

const AvatarItem = ({
  user,
  company
}: {
  user: IUser
  company: ICompany | null
}) => (
  <div className="tw-relative">
    <Avatar
      image={user.image}
      name={user.fullName}
      size={100}
      className={clsx(styles.avatar)}
    />
    {!!company && (
      <Avatar
        image={company.image}
        name={company.name}
        size={35}
        className={clsx(styles.subAvatar)}
      />
    )}
  </div>
)

const SafePaymentSummary: FC<
  PaymentSummaryProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    safeNote,
    fetchOne,
    fetchOneLoading,
    downloadPdf,
    downloadPdfLoading
  } = props

  const navigate = useNavigator()

  const [recipientCompany, setRecipientCompany] = useState<ICompany | null>(
    null
  )

  const fetchCompany = async () => {
    const response = await fetchOne({
      data: {
        id: safeNote.recipientCompanyId as string
      }
    })

    setRecipientCompany(response?.id ? response : undefined)
  }

  useEffect(() => {
    fetchCompany()
  }, [safeNote.recipientCompanyId])

  const handleViewClick = () => {
    navigate.toViewSafeNote(safeNote.id, undefined, {
      openInNewTab: true
    })
  }

  const handleBackClick = () => {
    navigate.toDashboard()
  }

  const handleDownload = () => {
    downloadPdf({ data: { safeNoteId: safeNote.id } })
  }

  const PreviewSave = (
    <div
      className={clsx(
        styles.blueBox,
        'tw-flex tw-justify-between tw-items-center'
      )}
    >
      <div className="tw-flex tw-items-center tw-gap-8">
        <div className={clsx(styles.receipt)}>
          <SharedIcons.Receipt size={20} color={Color.neutral400} />
        </div>
        View Executed SAFE
      </div>

      <Button
        className=""
        appearance="ordinary"
        width="fit"
        addonBefore={
          <SharedIcons.DownloadModern
            color={Color.primary400}
            size={20}
            className="tw-mr-2"
          />
        }
        onClick={handleDownload}
      >
        Download
      </Button>
    </div>
  )

  const renderTermsItem = (title: string, value: ReactNode) => (
    <Row gap={20} justify="between" items="center" className={styles.whiteBox}>
      <Text
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.MEDIUM}
        color={Color.neutral400}
      >
        {title}
      </Text>

      {value}
    </Row>
  )

  const relationsPreviewItem = (
    <div>
      <img src={transferArrowsImg} alt="transfer arrows" width={100} />
      <div className={clsx(styles.composition, 'tw-gap-37 tw-mb-30')}>
        <img
          src={starsImg}
          alt="stars"
          className="tw-aspect-square"
          width={100}
        />
        <div className={clsx(styles.composition, 'tw-gap-20')}>
          {AvatarItem({
            user: safeNote.recipient,
            company: recipientCompany
          })}

          {AvatarItem({
            user: safeNote.sender,
            company: safeNote.senderCompany
          })}
        </div>
        <img
          src={starsImg}
          alt="stars"
          className={clsx(styles.reverse, 'tw-aspect-square')}
          width={100}
        />
      </div>
    </div>
  )

  if (fetchOneLoading || downloadPdfLoading)
    return <FullScreenLoading loading />

  return (
    <div className={styles.summaryScreen}>
      <Lottie animationData={LottieConfetti} className={styles.leftConfetti} />

      <Lottie animationData={LottieConfetti} className={styles.rightConfetti} />

      <HeaderBar />

      <div className={styles.summaryScreenInner}>
        <div className={styles.content}>
          {relationsPreviewItem}

          <Heading
            type={HeadingTypes.H2}
            align={TextAlign.CENTER}
            color={Color.neutral500}
          >
            Congratulations on your investment!
          </Heading>
          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral300}
            align={TextAlign.CENTER}
            className="tw-mt-8"
          >
            Review your investment details, and download the SAFE and receipt
            for your records below.
          </Text>

          <Col gap={12} items="stretch" className="tw-w-full tw-mt-24">
            {PreviewSave}

            {renderTermsItem(
              'Company',
              <div className="tw-font-medium">
                {safeNote.senderCompany.name}
              </div>
            )}
            {renderTermsItem(
              'Investment amount:',
              <Badge type={BadgeType.INFO}>
                ${toCurrencyFormat(safeNote.safeAmount)}
              </Badge>
            )}

            {!safeNote.mfn && (
              <>
                {!!safeNote.discountRate &&
                  renderTermsItem(
                    'Discount:',
                    <Badge type={BadgeType.SUCCESS}>
                      {safeNote.discountRate}%
                    </Badge>
                  )}
                {!!safeNote.valuationCap &&
                  renderTermsItem(
                    'Valuation Cap:',
                    <Badge type={BadgeType.SUCCESS}>
                      ${toCurrencyFormat(safeNote.valuationCap)}
                    </Badge>
                  )}
              </>
            )}

            <div className="tw-flex tw-items-center tw-gap-20 tw-mt-10">
              <Button
                uppercase
                width="full"
                appearance="secondary"
                onClick={handleBackClick}
              >
                Back To Dashboard
              </Button>
              <Button uppercase width="full" onClick={handleViewClick}>
                View Safe
              </Button>
            </div>
          </Col>
        </div>
      </div>
    </div>
  )
}

export default withStore(mapStateToProps)(SafePaymentSummary)
