import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumberFormatValues } from 'react-number-format'
import { UsaStates } from 'usa-states'

import { ConditionalRender, UploadImage } from '@/common/components'
import {
  AddTeamMembers,
  IComponentTeamMember,
  IOwner
} from '@/features/auth/components/EntrepreneurForm/AddTeamMembers'
import { AngelSchema } from '@/features/auth/schemas/AuthSchema'
import {
  angelStepsMapper,
  useCompleteSignUpContext
} from '@/features/auth/views/CompleteSignUpView/context'
import {
  CreateAngelProfileDto,
  CreateCorporateAngelProfileDto,
  InvestorType,
  ITeamMember,
  IUser
} from '@/features/user'
import { ProfileTypes } from '@/features/user/enums'
import { Color } from '@/packages/palette'
import {
  BackButton,
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  IFile,
  NumberInput,
  Radio,
  Row,
  Select,
  Spacer,
  Step,
  Stepper,
  Text,
  TextInput,
  TextTypes,
  useStepper
} from '@/packages/ui'

import styles from './AngelForm.module.scss'

interface AngelFormProps {
  user?: IUser
  loading: boolean
  onBack?: () => void
  backDisabled?: boolean
  secondaryView?: boolean
  onScrollToTop?: () => void
  onFinish?: (
    values: CreateAngelProfileDto | CreateCorporateAngelProfileDto
  ) => void
}

const states = new UsaStates().states.map((item) => ({
  value: item.name.toLowerCase(),
  label: item.name
}))

const AngelForm: FC<AngelFormProps> = ({
  user,
  onBack,
  onFinish,
  loading,
  backDisabled,
  onScrollToTop,
  secondaryView
}: AngelFormProps) => {
  const [teamMembers, setTeamMembers] = useState<IComponentTeamMember[]>([])

  const [investorType, setInvestorType] = useState<InvestorType>(
    InvestorType.INDIVIDUAL
  )
  const [personalPhoto, setPersonalPhoto] = useState<any>(undefined)
  const [companyLogo, setCompanyLogo] = useState<IFile | undefined>(undefined)

  const { currentStep, prevStep, nextStep } = useStepper(0, 3)

  const { setStep } = useCompleteSignUpContext()

  const {
    watch,
    reset,
    control,
    register,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(AngelSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      ownerTitle: '',
      investorType: InvestorType.INDIVIDUAL,
      address1: undefined,
      address2: undefined,
      city: undefined,
      zipCode: undefined,
      state: undefined,
      country: 'USA',
      companyName: undefined
    }
  })

  const owner: IOwner = {
    email: user?.email || '',
    name: user?.fullName || '',
    position: watch('ownerTitle'),
    photo: personalPhoto
  }

  useEffect(() => {
    setValue('investorType', investorType)
    reset(getValues())
  }, [investorType])

  const handleBack = useCallback(() => {
    if (currentStep === 0) {
      onBack?.()
      return
    }

    prevStep()
  }, [currentStep])

  const handleNext = async () => {
    if (currentStep === 2) {
      const values = getValues()

      const addressData = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        country: values.country,
        state: values.state,
        zipCode: values.zipCode
      }

      const angelData: any = {
        name:
          investorType === InvestorType.INDIVIDUAL
            ? owner.name
            : values.companyName,
        address: addressData
      }

      const mappedTeamMembers: ITeamMember[] =
        teamMembers.map(
          (member) =>
            ({
              email: member.email,
              fullName: member.name,
              permission: member.permission
            } as ITeamMember)
        ) || []

      angelData.ownerPosition = owner.position
      angelData.teamMembers = mappedTeamMembers

      await onFinish?.({
        type: ProfileTypes.ANGEL,
        investorType,
        data: angelData,
        imageUrl: user?.image,
        image:
          investorType === InvestorType.INDIVIDUAL
            ? personalPhoto
            : companyLogo,
        userData: {
          fullName: owner.name,
          image: owner.photo
        }
      })
      return
    }

    nextStep()
  }

  useEffect(() => {
    onScrollToTop?.()

    setStep?.(angelStepsMapper[currentStep])
  }, [currentStep])

  const nextDisabled = (() => {
    if (currentStep === 0) {
      return !watch('ownerTitle') || !investorType || !owner.photo
    }

    return !isValid || loading
  })()

  const AddressFields = (
    <>
      <FormItem errors={errors.address1?.message}>
        <TextInput
          {...register('address1')}
          label="Address"
          placeholder="Address"
          invalid={FormHelper.isFieldInvalid('address1', errors)}
        />
      </FormItem>

      <FormItem>
        <TextInput
          {...register('address2')}
          label="Apartment, suite, etc."
          placeholder="Apartment, suite, etc."
        />
      </FormItem>

      <FormItem errors={errors.country?.message}>
        <TextInput
          {...register('country')}
          disabled
          label="Country"
          tooltip="We’re currently only working within the USA, but hold tight, MySAFEnotes will be available in other countries shortly."
          placeholder="Country"
          invalid={FormHelper.isFieldInvalid('country', errors)}
        />
      </FormItem>

      <FormItem errors={errors.state?.message}>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              name="state"
              label="State"
              placeholder="State"
              options={states}
              value={value!}
              onChange={(val: string) => onChange(val)}
            />
          )}
          name="state"
        />
      </FormItem>

      <FormItem errors={errors.city?.message}>
        <TextInput
          {...register('city')}
          label="City"
          placeholder="City"
          invalid={FormHelper.isFieldInvalid('city', errors)}
        />
      </FormItem>

      <FormItem errors={errors.zipCode?.message}>
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, value } }) => (
            <NumberInput
              value={value}
              onValueChange={(val: NumberFormatValues) => {
                onChange(val.value)
              }}
              label="Zip code"
              placeholder="Zip code"
              name="zipCode"
              invalid={FormHelper.isFieldInvalid('zipCode', errors)}
              thousandSeparator={false}
            />
          )}
        />
      </FormItem>
    </>
  )

  const IndividualInvestorDetails = (
    <Form schema={AngelSchema}>{AddressFields}</Form>
  )

  const CorporateInvestorDetails = (
    <Form schema={AngelSchema}>
      {/* <FormItem>
        <TextInput
          required
          label="Company name"
          placeholder="Company name"
          {...register('companyName')}
        />
      </FormItem>
      <UploadImage
        required
        modalTitle="Upload Company's logo"
        description="Showcase your company logo on your profile. You can always change this later in settings. "
        label="Company logo"
        caption="Upload logo (PNG or JPG)"
        image={companyLogo}
        onImageSelect={setCompanyLogo}
      /> */}
      {AddressFields}
    </Form>
  )

  const Step1 = (
    <>
      <Heading>Tell us about yourself</Heading>
      <Spacer size={16} />
      <Text type={TextTypes.BODY_DEFAULT} color={Color.neutral400}>
        Select if you’re an individual or corporate investor. Personalize your
        profile with a photo so we can get you started.
      </Text>

      <Spacer size={32} />
      <Row className={styles.radioContainer}>
        <Radio
          name="investorType"
          label="Individual investor"
          tooltip="Help your friends and family kickstart their ideas with seed funding"
          checked={investorType === InvestorType.INDIVIDUAL}
          onChange={() => setInvestorType(InvestorType.INDIVIDUAL)}
        />
        <Spacer size={40} vertical />
        <Radio
          name="investorType"
          label="Corporate investor"
          tooltip="Use this account to invest as a corporate investor"
          checked={investorType === InvestorType.CORPORATE}
          onChange={() => setInvestorType(InvestorType.CORPORATE)}
        />
      </Row>

      <Spacer size={25} />
      <Form schema={AngelSchema}>
        <FormItem errors={errors.ownerTitle?.message}>
          <TextInput
            label="Your title (role)"
            placeholder={`Example: ${
              investorType === InvestorType.INDIVIDUAL
                ? 'angel, managing partner...'
                : 'managing partner...'
            }`}
            {...register('ownerTitle')}
            invalid={FormHelper.isFieldInvalid('ownerTitle', errors)}
          />
        </FormItem>

        <UploadImage
          required
          description="Pick a photo to showcase on your profile. You can always change this later in settings. "
          image={personalPhoto}
          defaultFileName="profile_photo.png"
          label="Upload your photo"
          caption="Upload photo (PNG or JPG)"
          onImageSelect={setPersonalPhoto}
        />

        <ConditionalRender condition={investorType === InvestorType.CORPORATE}>
          <FormItem>
            <TextInput
              required
              label="Company name"
              placeholder="Company name"
              {...register('companyName')}
            />
          </FormItem>
          <UploadImage
            required
            modalTitle="Upload Company's logo"
            description="Showcase your company logo on your profile. You can always change this later in settings. "
            label="Company logo"
            caption="Upload logo (PNG or JPG)"
            image={companyLogo}
            onImageSelect={setCompanyLogo}
          />
        </ConditionalRender>
      </Form>
    </>
  )

  const Step2 = (
    <>
      <Heading>Tell us about yourself</Heading>
      <Spacer size={16} />
      <Text color={Color.neutral400} type={TextTypes.BODY_DEFAULT}>
        Provide some details to get started.
      </Text>

      <Spacer size={32} />
      <ConditionalRender
        condition={investorType === InvestorType.INDIVIDUAL}
        fallbackElement={CorporateInvestorDetails}
      >
        {IndividualInvestorDetails}
      </ConditionalRender>
    </>
  )

  useEffect(() => {
    if (user?.image && !personalPhoto) {
      setPersonalPhoto(user?.image)
    }
  }, [user?.image])

  return (
    <div className={clsx(styles.container, secondaryView && styles.fullHeight)}>
      <Stepper
        navigationType="circled"
        activeStep={currentStep}
        markPreviousAsCompleted={false}
        navigationClassName={styles.stepper}
      >
        <Step title="About you">{Step1}</Step>
        <Step title="Address">{Step2}</Step>
        <Step title="Team">
          <AddTeamMembers
            type={ProfileTypes.ANGEL}
            owner={owner}
            secondaryView={secondaryView}
            teamMembers={teamMembers}
            onUpdateTeamMembers={setTeamMembers}
            company={watch('companyName') || 'Company'}
          />
        </Step>
      </Stepper>

      <Spacer size={42} />
      <Row justify="between" gap={50} items="center">
        <ConditionalRender condition={!(backDisabled && currentStep === 0)}>
          <BackButton width="default" disabled={loading} onClick={handleBack}>
            Back
          </BackButton>
        </ConditionalRender>

        <Button
          width="default"
          loading={loading}
          onClick={handleNext}
          uppercase
          disabled={nextDisabled}
        >
          {currentStep === 2 ? 'Finish' : 'Next step'}
        </Button>
      </Row>
    </div>
  )
}

export default AngelForm
