import { observer } from 'mobx-react-lite'
import React from 'react'

import { AnchorLink } from '@/common/components'
import { StoreType, useStore } from '@/common/store'
import {
  SAFE_NOTE_TEMPLATE_ANCHORS,
  SafeNote,
  ViewSafeAs
} from '@/features/safe-note'
import { useCreateSafeNoteContext } from '@/features/safe-note/context/CreateSafeNoteContext'
import { useFullScreenContext } from '@/features/safe-note/context/FullScreenContext'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import {
  Col,
  FontWeight,
  Heading,
  HeadingTypes,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

const ReviewStep = () => {
  const { data, updateData, company, highlights, updateHighlights, onFinish } =
    useCreateSafeNoteContext()
  const { isFullScreenActive } = useFullScreenContext()

  const { me } = useStore((store: StoreType) => store.user)

  return (
    <Col className="tw-w-full" items="center">
      {!isFullScreenActive && (
        <>
          <Heading
            type={HeadingTypes.H2}
            color={Color.neutral500}
            align={TextAlign.CENTER}
          >
            Review and sign
          </Heading>

          <Text
            type={TextTypes.BODY_DEFAULT}
            color={Color.neutral400}
            align={TextAlign.CENTER}
            className="tw-mt-8 tw-mb-30 tw-max-w-[710px]"
          >
            If you’re sending this SAFE to multiple investors, you only need to
            add your signature once. We’ll duplicate it and sign the rest of the
            SAFEs for you.&nbsp;
            <AnchorLink to={SAFE_NOTE_TEMPLATE_ANCHORS.SIGNATURES}>
              <Text weight={FontWeight.SEMIBOLD} color={Color.primary400}>
                Review and sign
                <SharedIcons.Plus className="tw-ml-[8px]" />
              </Text>
            </AnchorLink>
          </Text>
        </>
      )}

      <SafeNote
        sender={me}
        companyName={company?.name || ''}
        stateOfIncorporation={company?.stateOfIncorporation || ''}
        recipients={data.recipients}
        mfn={data.mfn}
        //
        selectedTermsId={data.selectedTermsId}
        discountRate={data.discountRate}
        discountRateActive={data.discountRateActive}
        valuationCap={data.valuationCap}
        valuationCapActive={data.valuationCapActive}
        //
        viewAs={ViewSafeAs.SENDER}
        viewMode={false}
        onUpdateData={updateData}
        highlights={highlights}
        updateHighlights={updateHighlights}
        //
        signatures={data.signatures}
        //
        onFinish={onFinish}
      />
    </Col>
  )
}

export default observer(ReviewStep)
