import React, { FC, useMemo } from 'react'

import { ConditionalRender } from '@/common/components'
import { StoreType, useStore, withStore } from '@/common/store'
import addCompanyImage from '@/features/dashboard/assets/images/add-company.png'
import { CompanyCard } from '@/features/dashboard/components'
import { AddNewProfileModal } from '@/features/dashboard/modals'
import { ICompanySummary, ISummaryItem } from '@/features/profile/api/types'
import { ICompany } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Button, FontWeight, Loader, Text, TextTypes } from '@/packages/ui'
import { NoItems } from '@/packages/ui/table/views'

import styles from './CompaniesList.module.scss'
import noItemsImg from './images/no-companies.png'

interface CompaniesListProps {
  deleted?: boolean
  companies: ICompany[]
  sharedAmount: number
  sharedOpened: boolean
  search?: string
  summaries: ICompanySummary[]
  type: ProfileTypes.ANGEL | ProfileTypes.ENTREPRENEUR
}

const mapStateToProps = ({ profile }: StoreType) => ({
  loading: profile.functionLoading.fetchSummaries
})

interface IFormattedSummaries {
  [key: string]: ISummaryItem
}

const CompaniesList: FC<
  CompaniesListProps & ReturnType<typeof mapStateToProps>
> = (props) => {
  const {
    deleted,
    loading,
    search,
    summaries,
    type,
    companies,
    sharedAmount,
    sharedOpened
  } = props

  const noSharedCompanies = sharedAmount === 0 && sharedOpened
  const notFoundCompaniesWithName = !!search && !companies.length

  const formattedSummaries: IFormattedSummaries = useMemo(
    () =>
      summaries.reduce(
        (result: IFormattedSummaries, nextItem: ICompanySummary) => {
          const values = { ...result }

          values[nextItem.id] = nextItem.summary

          return values
        },
        {}
      ),
    [summaries]
  )

  const { visible, closeModal, openModal } = useModal<boolean>(false)
  const myId = useStore((store) => store.user.me?.id)

  const Modal = (
    <>
      {visible && (
        <AddNewProfileModal predefinedRole={type} closeModal={closeModal} />
      )}
    </>
  )

  if (loading) {
    return <Loader width="100%" />
  }

  if (noSharedCompanies || notFoundCompaniesWithName) {
    return (
      <>
        {Modal}
        <NoItems
          onActionClick={openModal}
          action={noSharedCompanies ? undefined : 'Create new company'}
          img={noItemsImg}
          title={
            notFoundCompaniesWithName
              ? 'No companies found'
              : 'You have not been invited to any other company yet'
          }
          description={
            notFoundCompaniesWithName
              ? 'You don’t have any companies with this name'
              : 'Currently, you haven’t received any invitations from other companies, but exciting opportunities may be just around the corner!'
          }
        />
      </>
    )
  }

  const AddCompanyCard = (
    <div className={styles.addCompany}>
      <img src={addCompanyImage} width={93} alt="" className="tw-mb-[24px]" />
      <Text
        color={Color.neutral500}
        type={TextTypes.BODY_DEFAULT}
        weight={FontWeight.BOLD}
        className="tw-mb-[16px]"
      >
        New Company
      </Text>

      <Button
        width="default"
        onClick={() => openModal()}
        uppercase
        className="!tw-p-10"
        textClassName="tw-flex tw-gap-5 tw-items-center !tw-text-[14px]"
      >
        <SharedIcons.PlusThin color={Color.neutral0} size={20} />
        Add new company
      </Button>
    </div>
  )

  return (
    <div className={styles.cards}>
      {Modal}
      <ConditionalRender condition={!sharedOpened && !deleted}>
        {AddCompanyCard}
      </ConditionalRender>
      {companies.map((company: ICompany) => (
        <CompanyCard
          deleted={deleted}
          company={company}
          myId={myId}
          key={company.id}
          summary={formattedSummaries?.[company.id]}
        />
      ))}
    </div>
  )
}

export default withStore(mapStateToProps)(CompaniesList)
