import { observer } from 'mobx-react-lite'
import { FC, useCallback, useState } from 'react'

import { useToast } from '@/common/hooks'
import { StoreType, useStore } from '@/common/store'
import {
  PaymentMethodData,
  PaymentOverview,
  PaymentStatus,
  PaymentType
} from '@/features/payment'
import { PaymentBridgeChildrenProps } from '@/features/payment/components'
import { IBankAccountItem } from '@/features/payment/components/BankItem'
import { ISafeNote } from '@/features/safe-note'
import { Loader, Row } from '@/packages/ui'

interface PaymentStepProps {
  safeNote?: ISafeNote
  setNextDisabled: (state: boolean) => void
  onPaymentComplete: () => void
}

declare global {
  interface Window {
    Plaid: any
  }
}

const PaymentStep: FC<PaymentStepProps> = (props) => {
  const { safeNote, setNextDisabled, onPaymentComplete } = props

  const {
    createSafeNotePaymentIntent,
    fetchPlaidLinkToken,
    exchangePublicTokenToAccessToken,
    loading
  } = useStore((store: StoreType) => store.payment)

  const { payForSafe, addPaymentRecord } = useStore(
    (store: StoreType) => store.safeNote
  )

  const toast = useToast()
  const [bankAccount, setBankAccount] = useState<IBankAccountItem | null>(null)
  const [paymentType, setPaymentMethod] = useState<PaymentType>(
    PaymentType.RECORD_PAYMENT
  )

  // useEffect(() => {
  //   getPlaidAccounts().then((response) => {
  //     if (response.accounts.length > 0) {
  //       const account = response.accounts[0]
  //       setBankAccount({
  //         id: account.account_id,
  //         name: account.name,
  //         last4Digits: +account.mask
  //       })
  //     }
  //   })
  // }, [])

  const handleVerifyBankAccount = useCallback(() => {
    fetchPlaidLinkToken().then((_token) => {
      if (!('Plaid' in window)) {
        toast.showWarning('Plaid is not initialized!')
        return
      }

      const handler = window.Plaid.create({
        token: _token,
        onSuccess: (publicToken: string, metadata: any) => {
          exchangePublicTokenToAccessToken({ data: { publicToken } }).then(
            () => {
              setBankAccount({
                // TODO define account id
                id: '',
                name: metadata.institution?.name,
                last4Digits: metadata.account?.mask
              })
            }
          )
        }
      })

      handler.open()
    })
  }, [])

  const handlePay = useCallback(
    ({
        processPayment,
        constructCardData,
        constructBankTransferData
      }: PaymentBridgeChildrenProps) =>
      async (_data: PaymentMethodData) => {
        if (!safeNote) {
          toast.showError(
            'SAFE note is not available. Please try again later...'
          )
          return
        }

        if (safeNote.paid) {
          toast.showInfo('Payment was already completed!')
          return
        }

        let response = null

        switch (paymentType) {
          case PaymentType.BANK_TRANSFER:
            if (!bankAccount?.id) return

            response = await payForSafe({
              data: {
                safeNoteId: safeNote.id,
                accountId: bankAccount?.id,
                amount: 10
              }
            })

            break

          case PaymentType.RECORD_PAYMENT:
            response = await addPaymentRecord({
              data: {
                safeNoteId: safeNote.id,
                amount: 10
              }
            })
            break

          default:
            toast.showInfo('Payment method not allowed!')
        }

        if (response?.payment && paymentType) {
          const status = await processPayment(paymentType, response, _data)

          if (
            [PaymentStatus.SUCCESS, PaymentStatus.PENDING].includes(status) &&
            paymentType === PaymentType.RECORD_PAYMENT
          ) {
            setNextDisabled(false)
            onPaymentComplete()
          }
        }
      },
    [createSafeNotePaymentIntent, bankAccount, paymentType]
  )

  if (!safeNote) return <></>

  if (loading) {
    return (
      <Row justify="center">
        <Loader />
      </Row>
    )
  }

  return (
    <PaymentOverview
      platformFee={0}
      stripeFee={0}
      paymentType={paymentType}
      // handler
      onSelectPaymentMethod={setPaymentMethod}
      onPay={handlePay}
      onVerifyBankAccount={handleVerifyBankAccount}
      // investor data
      recipientCompany={safeNote.senderCompany.name}
      recipientName={safeNote.sender.fullName}
      investorName={safeNote.recipient.fullName}
      investorCompany={safeNote.recipientCompany?.name || '-'}
      investmentAmount={safeNote?.safeAmount}
      bankAccount={bankAccount}
    />
  )
}

export default observer(PaymentStep)
