import clsx from 'clsx'
import React, { CSSProperties, FC, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { SharedImages } from '@/assets/images'
import { Avatar } from '@/common/components'
import { DateHelper } from '@/common/helpers'
import { SendReminder } from '@/features/dashboard/assets/icons/TableOptionsIcons'
import { useModalContext } from '@/features/modals'
import {
  SendSafeReminderModal,
  SignatureData,
  SignatureModal
} from '@/features/safe-note/modals'
import { IUser } from '@/features/user'
import { useModal } from '@/packages/hooks'
import { SharedIcons } from '@/packages/icons'
import { Color } from '@/packages/palette'
import { Col, FontWeight, Input, Row, Text, TextTypes } from '@/packages/ui'

import SignatureInfoModal from '../../../../modals/SignatureInfoModal/SignatureInfoModal'
import styles from './SafeNoteSignature.module.scss'

interface IProps {
  safeNoteId?: string
  user?: IUser | null
  viewMode?: boolean
  showReminder?: boolean
  signature: SignatureData | undefined
  signatureDate: Date | undefined
  signatureName: string | undefined
  updateName: (value: string) => void
  updateSignature: (value: SignatureData | undefined) => void
  showSignatureInfoModal?: boolean
}

const SafeNoteSignatureNew: FC<IProps> = (props) => {
  const {
    user,
    safeNoteId,
    viewMode,
    signature,
    signatureDate,
    signatureName,
    updateName,
    updateSignature,
    showReminder,
    showSignatureInfoModal
  } = props

  const [viewedInfo, setViewedInfo] = useLocalStorage(
    'viewedInfoAboutSignature',
    false
  )

  const { reminderSentModal } = useModalContext()
  const { visible, openModal, closeModal } = useModal<
    'info' | 'signature' | 'reminder'
  >()
  const [editNameMode, setEditNameMode] = useState(false)

  const nameLength = signatureName?.length || user?.fullName?.length || 10

  const isSignatureAdded = !!signature?.signature

  if (!user?.id) return null

  const onSignatureBoxClick = () => {
    if (viewMode) return

    if (viewedInfo) {
      openModal('signature')
    } else if (showSignatureInfoModal) {
      openModal('info')
    } else {
      // TODO: FIX THIS LOGIC
      openModal('signature')
    }
  }

  const handleAddSignature = (data: SignatureData) => {
    if (viewMode) return

    updateSignature(data)
    closeModal()
  }

  const onBlur = () => {
    setEditNameMode(false)

    if (!signatureName) {
      updateName(user?.fullName)
    }
  }

  return (
    <Col
      gap={24}
      items="center"
      className={clsx(
        styles.signatureContainer,
        isSignatureAdded && styles.signed,
        viewMode && styles.viewMode
      )}
    >
      {isSignatureAdded && (
        <SharedIcons.CheckmarkWithoutBg
          className={styles.signedMark}
          color={Color.success400}
          size={20}
        />
      )}

      {visible === 'info' && (
        <SignatureInfoModal
          closeModal={closeModal}
          onNext={() => {
            setViewedInfo(true)
            openModal('signature')
          }}
        />
      )}

      {visible === 'signature' && (
        <SignatureModal
          closeModal={closeModal}
          onAddSignature={handleAddSignature}
          userName={signatureName}
          {...signature}
        />
      )}

      {visible === 'reminder' && (
        <SendSafeReminderModal
          onSent={() => {
            closeModal()
            reminderSentModal.openModal()
          }}
          id={safeNoteId as string}
          name={signatureName as string}
          img={user?.image}
          closeModal={closeModal}
        />
      )}

      {showReminder && (
        <Text
          clickable
          className={styles.remindBtn}
          type={TextTypes.BODY_SMALL}
          weight={FontWeight.SEMIBOLD}
          color={Color.primary500}
          onClick={() => openModal('reminder')}
        >
          <SendReminder size={18} color={Color.primary500} />
          Remind
        </Text>
      )}

      <Col items="center" className="tw-mt-[-27px]">
        <Avatar
          size={54}
          name={signatureName}
          image={user.image}
          defaultImage={SharedImages.UserPlaceholder}
        />

        {editNameMode ? (
          <Input
            fitContent
            type="text"
            autoFocus
            value={signatureName}
            onChange={(e) => updateName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onBlur()
              }
            }}
            style={{ '--length': `${nameLength * 12}px` } as CSSProperties}
            onBlur={onBlur}
            containerClassName={styles.signatureNameInput}
          />
        ) : (
          <Text
            clickable={!viewMode}
            onClick={() => !viewMode && setEditNameMode(true)}
            type={TextTypes.BODY_DEFAULT}
            weight={FontWeight.MEDIUM}
            color={Color.neutral500}
            className="tw-mt-8 tw-mb-2"
          >
            {signatureName}
          </Text>
        )}

        <Text
          type={TextTypes.BODY_EXTRA_SMALL}
          weight={FontWeight.MEDIUM}
          color={Color.neutral300}
        >
          {user.email}
        </Text>
      </Col>

      <div
        className={clsx(
          styles.signature,
          !signatureDate && 'tw-cursor-pointer'
        )}
        onClick={onSignatureBoxClick}
      >
        {isSignatureAdded ? (
          <img
            className={styles.signatureImg}
            src={signature.signature}
            alt="your-signature"
          />
        ) : (
          <Text
            type={TextTypes.BODY_SMALL}
            weight={FontWeight.MEDIUM}
            color={viewMode ? Color.neutral300 : Color.primary400}
            className={clsx(
              '!tw-flex tw-items-center !tw-leading-snug tw-gap-2',
              viewMode && 'tw-italic'
            )}
          >
            {viewMode ? 'Not signed yet' : 'Review and sign '}
            {!viewMode && (
              <SharedIcons.PlusThin size={15} color={Color.primary400} />
            )}
          </Text>
        )}

        {isSignatureAdded && !viewMode && (
          <Row items="center" gap={4} className={styles.actions}>
            <SharedIcons.PencilModern
              size={20}
              color={Color.neutral300}
              clickable
              onClick={() => openModal('signature')}
            />

            <SharedIcons.Trash
              size={20}
              color={Color.neutral300}
              clickable
              onClick={(e) => {
                e.stopPropagation()
                updateSignature(undefined)
              }}
            />
          </Row>
        )}
      </div>

      <div className={styles.dateContainer}>
        <Text type={TextTypes.BODY_EXTRA_SMALL} color={Color.neutral300}>
          <i>
            {isSignatureAdded
              ? DateHelper.format(signatureDate)
              : 'Date signed'}
          </i>
        </Text>
      </div>
    </Col>
  )
}

export default SafeNoteSignatureNew
