import clsx from 'clsx'
import React, { FC, useMemo, useState } from 'react'

import { StaticIcons } from '@/assets/icons'
import {
  ArrowNavigation,
  Avatar,
  ConditionalRender,
  VerificationStatusBadge
} from '@/common/components'
import { FormatterHelper } from '@/common/helpers'
import { IncomingSafeIcons } from '@/features/dashboard/assets'
import { ProfileTypes } from '@/features/user/enums'
import { SafeMini } from '@/packages/icons/Icons'
import { Color } from '@/packages/palette'
import {
  Badge,
  BadgeType,
  FontWeight,
  Heading,
  HeadingTypes,
  Row,
  Spacer,
  Text,
  TextAlign,
  TextTypes
} from '@/packages/ui'

import styles from './PendingSafes.module.scss'

interface PendingSafesProps {
  pendingSafes: any[]
  selectedRole?: ProfileTypes
}
const PendingSafes: FC<PendingSafesProps> = (props) => {
  const { pendingSafes, selectedRole } = props

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const selectedSafe = pendingSafes[activeIndex]
  const { safeAmount } = selectedSafe

  const wrongRoleSelected = selectedRole && selectedRole !== ProfileTypes.ANGEL

  const moreThanOne = pendingSafes?.length > 1

  const footerMessage = useMemo(() => {
    const safeNumberMessage = pendingSafes?.length === 1 ? 'SAFE' : "SAFE's"

    if (wrongRoleSelected) {
      return 'You can’t invest as an entrepreneur'
    }

    return `You can invest with this role for your ${pendingSafes?.length} pending ${safeNumberMessage}`
  }, [wrongRoleSelected, pendingSafes?.length])

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.selectedSafe,
          wrongRoleSelected && styles.disabled
        )}
      >
        <div className={styles.selectedSafeInner}>
          <ConditionalRender condition={moreThanOne}>
            <div className={clsx(styles.background, styles.level1)} />
            <div className={clsx(styles.background, styles.level2)} />
            <div className={clsx(styles.background, styles.level3)} />
          </ConditionalRender>

          <div className={styles.senderImage}>
            <Avatar
              size={60}
              name={selectedSafe?.sender?.fullName}
              image={selectedSafe?.sender?.image}
            />

            <Avatar
              size={25}
              defaultImage={StaticIcons.CompanyPlaceholder}
              className={styles.companyImage}
              name={selectedSafe?.senderCompany?.name}
              image={selectedSafe?.senderCompany?.logo}
            />
          </div>

          <Text
            type={TextTypes.BODY_MAIN}
            weight={FontWeight.BOLD}
            align={TextAlign.CENTER}
            color={Color.neutral500}
            className="tw-w-full"
          >
            {selectedSafe?.sender?.fullName} sent you a SAFE!
          </Text>

          <div className={styles.safeAmount}>
            Investment amount:
            <Text
              type={TextTypes.BODY_MAIN}
              weight={FontWeight.SEMIBOLD}
              color={Color.primary400}
            >
              {FormatterHelper.toCurrency(safeAmount)}
            </Text>
          </div>

          <Row
            items="center"
            justify="between"
            gap={20}
            className={styles.borderBottom}
          >
            <Row items="center" gap={5}>
              <IncomingSafeIcons.Company color={Color.neutral400} />
              <Text
                type={TextTypes.BODY_SMALL}
                color={Color.neutral400}
                weight={FontWeight.MEDIUM}
              >
                Company:
              </Text>
            </Row>

            <Row gap={4} items="center">
              <Text type={TextTypes.BODY_SMALL} weight={FontWeight.SEMIBOLD}>
                {selectedSafe?.senderCompany?.name}
              </Text>
              <VerificationStatusBadge
                mini
                company={selectedSafe?.senderCompany}
                isCreator={false}
              />
            </Row>
          </Row>

          <ConditionalRender condition={!!selectedSafe.discountRate}>
            <Row
              items="center"
              justify="between"
              gap={20}
              className={
                selectedSafe.valuationCap ? styles.borderBottom : 'tw-py-[8px]'
              }
            >
              <Row items="center" gap={5}>
                <IncomingSafeIcons.DiscountRate color={Color.neutral400} />
                <Text
                  type={TextTypes.BODY_SMALL}
                  color={Color.neutral400}
                  weight={FontWeight.MEDIUM}
                >
                  Discount Rate:
                </Text>
              </Row>
              <Badge
                size="small"
                type={
                  selectedSafe.discountRate ? BadgeType.SUCCESS : BadgeType.INFO
                }
              >
                {selectedSafe.discountRate
                  ? `${100 - selectedSafe.discountRate}%`
                  : 'TBD'}
              </Badge>
            </Row>
          </ConditionalRender>

          <ConditionalRender condition={!!selectedSafe.valuationCap}>
            <Row
              items="center"
              justify="between"
              gap={20}
              className="tw-py-[8px]"
            >
              <Row items="center" gap={5}>
                <IncomingSafeIcons.ValuationCap color={Color.neutral400} />
                <Text
                  type={TextTypes.BODY_SMALL}
                  color={Color.neutral400}
                  weight={FontWeight.MEDIUM}
                >
                  Valuation cap:
                </Text>
              </Row>
              <Badge
                size="small"
                type={
                  selectedSafe.valuationCap ? BadgeType.SUCCESS : BadgeType.INFO
                }
              >
                {selectedSafe.valuationCap
                  ? FormatterHelper.toCurrency(selectedSafe?.valuationCap)
                  : 'TBD'}
              </Badge>
            </Row>
          </ConditionalRender>

          <ConditionalRender condition={selectedSafe.mfn}>
            <Row
              items="center"
              justify="between"
              gap={20}
              className="tw-py-[8px]"
            >
              <Row items="center" gap={5}>
                <IncomingSafeIcons.MFN color={Color.neutral400} />
                <Text
                  type={TextTypes.BODY_SMALL}
                  color={Color.neutral400}
                  weight={FontWeight.MEDIUM}
                >
                  Terms:
                </Text>
              </Row>
              <Badge size="small" type={BadgeType.INFO}>
                MFN
              </Badge>
            </Row>
          </ConditionalRender>
        </div>
      </div>

      <ConditionalRender condition={moreThanOne}>
        <ArrowNavigation
          active={activeIndex}
          setActive={setActiveIndex}
          size={pendingSafes.length}
        />
      </ConditionalRender>

      <ConditionalRender condition={!!selectedRole}>
        <div
          className={clsx(
            styles.asideFooter,
            wrongRoleSelected && styles.wrongRole
          )}
        >
          <SafeMini
            color={wrongRoleSelected ? Color.warning300 : Color.success400}
          />
          {footerMessage}
        </div>
      </ConditionalRender>

      <Spacer size={0} />
      <ConditionalRender
        condition={!!wrongRoleSelected}
        fallbackElement={
          <Heading
            weight={FontWeight.BOLD}
            type={HeadingTypes.H1}
            color={Color.neutral500}
          >
            You have <br />
            <span className={styles.number}>
              {pendingSafes?.length} pending
            </span>{' '}
            {pendingSafes.length === 1 ? 'SAFE' : 'SAFEs'}.
          </Heading>
        }
      >
        <Heading
          weight={FontWeight.BOLD}
          type={HeadingTypes.H2}
          color={Color.neutral500}
        >
          You cannot accept the pending{' '}
          {pendingSafes.length === 1 ? 'SAFE' : 'SAFEs'} as an entrepreneur.
        </Heading>
      </ConditionalRender>

      <Text type={TextTypes.BODY_MAIN} color={Color.neutral400}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Raise seed funding, invest, advise, or oversee clients' SAFE
        investments.
      </Text>
    </div>
  )
}

export default PendingSafes
