import clsx from 'clsx'
import html2canvas from 'html2canvas'
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { useSmartState } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Button,
  FontWeight,
  Heading,
  HeadingTypes,
  IModalWithCloseFn,
  Modal,
  Row,
  Spacer,
  Tab,
  Tabs,
  Text,
  TextAlign,
  TextInput,
  TextTypes
} from '@/packages/ui'

import { SAFE_NOTE_WRAPPER_ID } from '../../constants'
import styles from './SignatureModal.module.scss'

export enum SignatureType {
  AUTO = 'autoSignature',
  MANUAL = 'typeSignature',
  DRAW = 'drawSignature'
}

export interface SignatureData {
  signature: any
  type?: SignatureType
  typeSignature?: string
  typeSignatureIndex?: number
  name?: string
  currentTabId?: SignatureType
}

type SignatureModalProps = IModalWithCloseFn & {
  userName?: string
  signature?: any
  typeSignature?: string
  typeSignatureIndex?: number
  type?: string
  onAddSignature?: (data: SignatureData) => void
}

const SignatureModal: FC<SignatureModalProps> = ({
  closeModal,
  onAddSignature,
  ...props
}: SignatureModalProps) => {
  const autoSignatureRef = useRef<any>()
  const typeSignatureRef = useRef<any>()
  const drawSignatureRef = useRef<any>()

  const [state, setState] = useSmartState({
    currentTabId: '',
    autoSignature: '',
    typeSignatureIndex: 0,
    typeSignature: '',
    addSignatureDisabled: false
  })

  const fontClass = useMemo(
    () => styles[`font${state.typeSignatureIndex}`],
    [state.typeSignatureIndex]
  )

  useEffect(() => {
    setState({
      autoSignature: props.userName,
      typeSignature: props.typeSignature || props.userName,
      typeSignatureIndex: props.typeSignatureIndex
    })

    if (props.type === SignatureType.MANUAL) {
      setState({ currentTabId: 'typeSignature' })
    }

    if (props.type === SignatureType.DRAW) {
      setState({ currentTabId: 'drawSignature' })
      setTimeout(() => {
        if (props.signature && drawSignatureRef) {
          drawSignatureRef.current?.fromDataURL(props.signature)
        }
      }, 100)
    }
  }, [
    props.signature,
    props.userName,
    props.typeSignature,
    props.typeSignatureIndex,
    props.type
  ])

  useEffect(() => {
    let disabled = false

    if (state.currentTabId === SignatureType.MANUAL) {
      disabled = state.typeSignature.trim() === ''
    }

    setState({ addSignatureDisabled: disabled })
  }, [state.currentTabId, state.typeSignature])

  const clearDrawSignature = useCallback(() => {
    drawSignatureRef.current.clear()
  }, [])

  const getDataUrlFromEl = useCallback(async (el: any) => {
    const canvas = await html2canvas(el, { backgroundColor: null })
    return canvas.toDataURL()
  }, [])

  const handleAddSignature = useCallback(async () => {
    let elRef = autoSignatureRef
    let signatureType = SignatureType.AUTO

    if (state.currentTabId === 'typeSignature') {
      elRef = typeSignatureRef
      signatureType = SignatureType.MANUAL
    }

    if (state.currentTabId === 'drawSignature') {
      signatureType = SignatureType.DRAW

      const dataUrl = drawSignatureRef.current.toDataURL()
      const isEmpty = drawSignatureRef.current.isEmpty()

      onAddSignature?.({
        signature: !isEmpty ? dataUrl : undefined,
        type: signatureType,
        typeSignature: state.typeSignature,
        typeSignatureIndex: state.typeSignatureIndex,
        name: props.userName
      })

      return
    }

    // auto signature
    const signature = await getDataUrlFromEl(elRef.current)

    onAddSignature?.({
      signature,
      type: signatureType,
      typeSignature: state.typeSignature,
      typeSignatureIndex: state.typeSignatureIndex,
      name: props.userName
    })
  }, [state, onAddSignature])

  return (
    <Modal
      size="md"
      id={SAFE_NOTE_WRAPPER_ID}
      className={styles.container}
      visible
      setVisible={closeModal}
    >
      <Heading type={HeadingTypes.H3}>Sign your SAFE note</Heading>
      <Spacer size={15} />

      <Text type={TextTypes.BODY_SMALL}>
        Use the options below to sign your SAFE.
      </Text>

      <Spacer size={10} />
      <Tabs
        kind="simple"
        initialTabId={state.currentTabId}
        onTabChange={(id) => setState({ currentTabId: id })}
      >
        <Tab id={SignatureType.AUTO} title="Automatic Signature">
          <div className={styles.autoSignature}>
            <p ref={autoSignatureRef} className={styles.signature}>
              {state.autoSignature}
            </p>
          </div>

          <Spacer size={10} />
          <Text type={TextTypes.BODY_SMALL} align={TextAlign.JUSTIFY}>
            By adding this signature I understand and agree that this is a legal
            representation of my signature. The SAFE Note will be legally
            binding between me and the other party.&nbsp;
            <Text
              asSpan
              type={TextTypes.BODY_SMALL}
              color={Color.primary400}
              weight={FontWeight.BOLD}
            >
              Terms And Conditions
            </Text>
          </Text>
        </Tab>

        <Tab id={SignatureType.MANUAL} title="Type Signature">
          <div className={styles.typeSignature}>
            <h1
              ref={typeSignatureRef}
              className={clsx(styles.textToSave, fontClass)}
            >
              {state.typeSignature}
            </h1>

            <h1 className={clsx(styles.text, fontClass)}>
              {state.typeSignature}
            </h1>

            <Spacer size={10} />
            <TextInput
              value={state.typeSignature}
              onChange={(e) => setState({ typeSignature: e.target.value })}
            />

            <div className={styles.variants}>
              {Array.from({ length: 4 }).map((_, index) => (
                <div
                  key={index}
                  className={clsx(
                    styles.variant,
                    state.typeSignatureIndex === index && styles.active
                  )}
                  onClick={() => setState({ typeSignatureIndex: index })}
                >
                  <Heading
                    className={styles[`font${index}`]}
                    align={TextAlign.CENTER}
                  >
                    {state.typeSignature || 'No data'}
                  </Heading>
                </div>
              ))}
            </div>
          </div>
        </Tab>

        <Tab id={SignatureType.DRAW} title="Draw Signature">
          <div className={styles.drawSignature}>
            <SignatureCanvas
              ref={(ref) => {
                drawSignatureRef.current = ref
              }}
              canvasProps={{ className: styles.pad }}
            />
            <Row items="center" justify="between">
              <Text color={Color.neutral200} type={TextTypes.BODY_DEFAULT}>
                Sign above
              </Text>
              <Text
                color={Color.primary400}
                type={TextTypes.BODY_DEFAULT}
                clickable
                onClick={clearDrawSignature}
              >
                Clear
              </Text>
            </Row>
          </div>
        </Tab>
      </Tabs>

      <Spacer size={40} />
      <Row gap={50} justify="center">
        <Button
          appearance="secondary"
          width="default"
          onClick={closeModal}
          uppercase
        >
          Cancel
        </Button>
        <Button
          width="default"
          uppercase
          onClick={handleAddSignature}
          disabled={state.addSignatureDisabled}
        >
          Add signature
        </Button>
      </Row>
    </Modal>
  )
}

export default SignatureModal
