import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  ArrowNavigation,
  ConditionalRender,
  UploadImage
} from '@/common/components'
import { StoreType, withStore } from '@/common/store'
import { capitalize } from '@/common/utils'
import { permissions } from '@/features/auth/components/EntrepreneurForm/AddTeamMembers/AddTeamMembers'
import { InviteStatus } from '@/features/auth/enums'
import { JoinToCompanySchema } from '@/features/auth/schemas/AuthSchema'
import { Invitations } from '@/features/dashboard/modals/InvitationsModal/parts'
import { ICompany, ICompanyTeamMember } from '@/features/profile/types'
import { ProfileTypes } from '@/features/user/enums'
import { useModal } from '@/packages/hooks'
import { Color } from '@/packages/palette'
import {
  Button,
  Form,
  FormHelper,
  FormItem,
  Heading,
  HeadingTypes,
  IFile,
  Loader,
  Row,
  Spacer,
  TextInput
} from '@/packages/ui'
import { useNavigator } from '@/router'

import CompanyRow from '../../../dashboard/modals/InvitationsModal/parts/CompanyRow'
import { RequestUpgradePermission } from '../../modals'
import DeclineRequestModal from '../../modals/DeclineRequestModal/DeclineRequestModal'
import styles from './JoinToCompany.module.scss'

const mapStateToProps = ({ user }: StoreType) => ({
  updateTeamMember: user.updateTeamMember,
  removeCompanyFromList: user.removeCompanyFromList,
  me: user.me,
  sortedCompanies: user.sortedCompanies,
  updateCurrentUser: user.updateCurrentUser,
  refetchCompany: user.refetchCompany
})

type JoinToCompanyProps = ReturnType<typeof mapStateToProps> & {
  companies: ICompany[]
  toSelectRole: () => void
  setJoinRole: (role: ProfileTypes) => void
}

const JoinToCompany: FC<JoinToCompanyProps> = (props) => {
  const navigate = useNavigator()

  const {
    me,
    companies,
    setJoinRole,
    toSelectRole,
    sortedCompanies,
    refetchCompany,
    updateTeamMember,
    updateCurrentUser,
    removeCompanyFromList
  } = props

  const { visible, closeModal, openModal } = useModal<
    'upgrade' | 'decline' | 'requestSent'
  >()

  const [loading, setLoading] = useState<boolean>(false)
  const [photo, setPhoto] = useState<IFile | undefined>(undefined)
  const [step, setStep] = useState<'invites' | 'accept'>('invites')
  const [activeRequest, setActiveRequest] = useState<number>(0)

  const currentRequest = companies?.[activeRequest] || {}

  const {
    getValues,
    register,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(JoinToCompanySchema),
    reValidateMode: 'onChange',
    mode: 'onChange'
  })

  const acceptedTeamMembers = currentRequest?.teamMembers?.filter?.(
    (teamMember: ICompanyTeamMember) =>
      teamMember.inviteStatus === InviteStatus.Accepted
  )
  const meAsTeamMember = currentRequest?.teamMembers?.find?.(
    (teamMember: ICompanyTeamMember) => teamMember.userId === me?.id
  )
  const workspace = capitalize(currentRequest?.type)
  const myPermission = permissions(!!me?.activeSubscription).find(
    (permission) => permission.value === meAsTeamMember?.permission
  )

  const handleAccept = async () => {
    const { title } = getValues()

    const isLastRequest = companies.length === 1

    if (photo) {
      await updateCurrentUser({ data: { payload: { image: photo } } })
      setPhoto(undefined)
    }

    await updateTeamMember({
      data: {
        companyId: currentRequest.id,
        teamMemberId: meAsTeamMember?.userId as string,
        values: { inviteStatus: InviteStatus.Accepted, position: title }
      }
    })

    if (isLastRequest) {
      await refetchCompany({ data: { id: currentRequest.id } })
      navigate.toDashboard()

      return
    }

    await refetchCompany({ data: { id: currentRequest.id } })
    setActiveRequest(0)
    setStep('invites')
  }

  const handleDecline = async () => {
    const isLastRequest = companies.length === 1

    setLoading(() => true)

    try {
      closeModal()

      await updateTeamMember({
        data: {
          companyId: currentRequest.id,
          teamMemberId: meAsTeamMember?.userId as string,
          values: { inviteStatus: InviteStatus.Declined }
        }
      })

      if (isLastRequest) {
        await removeCompanyFromList(currentRequest.id)

        if (
          sortedCompanies.angel.length ||
          sortedCompanies.entrepreneur.length
        ) {
          navigate.toDashboard()
        } else {
          toSelectRole()
        }

        setLoading(() => false)
        return
      }

      await removeCompanyFromList(currentRequest.id)
      setActiveRequest(0)

      setLoading(() => false)
    } catch {
      setLoading(() => false)
    }
  }

  const sendUpgradeRequest = (newValue: string) => {
    openModal('requestSent')

    const newPermission = permissions().find(
      (item: any) => item.value === newValue
    )

    setValue('requestedPermission', newPermission?.label)
  }

  useEffect(() => {
    setJoinRole(currentRequest?.type)
  }, [activeRequest])

  useEffect(() => {
    if (myPermission) {
      setValue('permission', myPermission.label)
    }
  }, [myPermission])

  const AcceptScreen = (
    <div>
      {visible === 'upgrade' && (
        <RequestUpgradePermission
          permission={myPermission}
          closeModal={closeModal}
          sendRequest={sendUpgradeRequest}
        />
      )}

      <Heading type={HeadingTypes.H2} color={Color.neutral500}>
        Share with us your personal details and move on like a member of:
      </Heading>

      <CompanyRow
        pageMode
        teamMembers={acceptedTeamMembers}
        name={currentRequest?.name}
        image={currentRequest?.image}
      />

      <Form schema={JoinToCompanySchema}>
        <FormItem errors={errors.title?.message}>
          <TextInput
            {...register('title')}
            label="Your company title (role)"
            placeholder="Company Title"
            invalid={FormHelper.isFieldInvalid('title', errors)}
          />
        </FormItem>

        <Spacer size={5} />

        <ConditionalRender condition={!me?.image}>
          <UploadImage
            description="Pick a photo to showcase on your profile. You can always change this later in settings. "
            label="Upload Photo"
            caption="Upload Photo (PNG or JPG)"
            image={photo}
            onImageSelect={setPhoto}
          />
        </ConditionalRender>

        <TextInput
          {...register('permission')}
          disabled
          label="Your Permission"
          placeholder="Permission"
          invalid={FormHelper.isFieldInvalid('permission', errors)}
        />
      </Form>

      <div className={styles.footer}>
        <Button
          uppercase
          appearance="secondary"
          width="full"
          onClick={() => setStep('invites')}
        >
          Back
        </Button>
        <Button
          disabled={!isValid || loading}
          uppercase
          width="full"
          onClick={handleAccept}
        >
          {loading ? 'Loading...' : 'Finish'}
        </Button>
      </div>
    </div>
  )

  return (
    <ConditionalRender
      condition={!loading && !!companies.length}
      fallbackElement={<Loader width="100%" />}
    >
      <div>
        {visible === 'decline' && (
          <DeclineRequestModal
            currentRequest={currentRequest}
            closeModal={closeModal}
            teamMembers={acceptedTeamMembers}
            handleDecline={handleDecline}
          />
        )}

        <ConditionalRender
          condition={step === 'invites'}
          fallbackElement={AcceptScreen}
        >
          <Invitations
            pageMode
            onDecline={() => openModal('decline')}
            onAccept={() => setStep('accept')}
            workspace={workspace}
            currentRequest={currentRequest}
            permissionLabel={capitalize(myPermission?.label || 'Collaborator')}
            acceptedTeamMembers={acceptedTeamMembers}
          />

          {companies.length > 1 && (
            <Row className={styles.navigation} justify="center">
              <ArrowNavigation
                active={activeRequest}
                setActive={setActiveRequest}
                size={companies.length}
              />
            </Row>
          )}
        </ConditionalRender>
      </div>
    </ConditionalRender>
  )
}

export default withStore(mapStateToProps)(JoinToCompany)
